import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { Modal, Box } from '@mui/material';
import Details from '../../components/Information';
import { Link } from 'react-scroll';

import PageTitle from '../../components/PageTitle';
import Seo from '../../components/Seo';
import FreeConsultation from '../Index/Banners/FreeConsultation';

import icon1 from '../../img/school/like-icon.svg';
import icon2 from '../../img/school/price-icon.svg';
import icon3 from '../../img/school/star-icon.svg';
import checkIcon from '../../img/information/check.svg';
import checkMark from '../../img/check_mark.svg';
import checkNotMark from '../../img/check_mark_not.svg';
import importantImg from '../../img/information/img.png';
import social1 from '../../img/social/footer/inst.svg';
import social2 from '../../img/social/footer/vk.svg';
import social3 from '../../img/social/footer/tg.svg';
import social4 from '../../img/social/footer/fb.svg';
import youtubeIcon from '../../img/social/youtube.svg';
import '../InformationAustria/style.scss';
import 'swiper/css';
import "swiper/css/navigation";


const InformationGermany = () => {

  const [ stateTabs, setStateTabs ] = useState(false);
  const [ modalUniversity, setModalUniversity ] = useState({
    title: '',
    list: ''
  });
  const [ isOpenModal, setIsOpen ] = useState(false);

  const handleOpenModal = (data: string) => {
    switch (data) {
      case "a1":
        setModalUniversity({
          title: 'Университеты Вены, с которыми мы работаем',
          list: "● Венский университет<br>● Венский технический университет<br>● Венский экономический университет<br>● Венский медицинский университет<br>● Венский ветеринарный университет<br>● Венский университет прикладных наук<br>● Венский аграрный университет BOKU<br>● Университет Зигмунда Фрейда<br>● Венский университет искусств<br>● Венская академия изобразительных искусств<br>● Венский университет музыки и исполнительского искусства<br>● Университет Webster"
        });
        break;
      case "a2":
        setModalUniversity({
          title: "Университеты Граца, с которыми мы работаем",
          list: "● Грацский медицинский университет<br>● Грацский университет Карла и Франца<br>● Грацский технический университет<br>● Университет музыки и театра Граца"
        });
        break;
      case "a4":
        setModalUniversity({
          title: "Университеты Линца, с которыми мы работаем",
          list: "● University of Art and Design Linz<br>● Университет Кеплера в Линце"
        });
        break;
      case "a3":
        setModalUniversity({
          title: "Университеты Зальцбурга, с которыми мы работаем",
          list: "● University of Salzburg<br>● Paracelsus Private Medical University of Salzburg<br>● Fachhochschule Salzburg<br>● University of Salzburg Business School"
        });
        break; 
      case "a5":
        setModalUniversity({
          title: "Университеты Инсбрука, с которыми мы работаем",
          list: "● Инсбрукский медицинский университет<br>● The Management Center Innsbruck<br>● Инсбрукский университет имени Леопольда и Франца"
        });
        break;  
      case "b1":
        setModalUniversity({
          title: "Университеты Берлина, с которыми мы работаем",
          list: "● Свободный университет Берлина<br>● Шарите<br>● Берлинский университет имени Гумбольдта<br>● Технический университет Берлина <br>● Технический институт прикладных наук им. Бойта <br>● Технико-экономический институт прикладных наук<br>● Институт прикладных наук экономики и права<br>● Берлинский университет искусств <br>● Институт прикладных наук им. Алисы Соломон<br>● Высшее художественное учебное заведение Вайсензее <br><br>Studienkollege Берлина, с которыми мы работаем<br>● Studienkolleg при гуманитарных вузах Берлина <br>● Studienkolleg при технических вузах Берлина <br>● Рейнский Studienkolleg Берлин (Платный)  <br>● Studienkolleg Берлин (Платный)  <br>● Studienkolleg Штайнке Институт (Платный)  "
        });
        break; 
      case "b2":
        setModalUniversity({
          title: "Университеты Мюнхена, с которыми мы работаем",
          list: "● Мюнхенский университет<br>● Людвига-Максимилиана<br>● Мюнхенский технический университет<br>● Мюнхенский университет прикладных наук<br>● Академия изобразительных искусств Мюнхена<br>● Военный университет Мюнхена<br>● Военный университет Мюнхена, курсы прикладных наук<br>● Университет государственной службы Баварии<br>● Университет музыки и театра Мюнхен<br>● Университет политологии Мюнхена<br>● Университет телевидения и кино Мюнхен<br><br>Studienkollege Мюнхена, с которыми мы работаем<br>● Studienkolleg Мюнхен"
        });
        break; 
      case "b3":
        setModalUniversity({
          title: "Университеты Франкфурт-на-Майне, с которыми мы работаем",
          list: "● Франкфуртский университет имени Иоганна Вольфганга Гёте<br>● Государственный университет изобразительных искусств Франкфурт<br>● Европейский университет прикладных наук Франкфурт-на-Майне<br>● Университет Гёте<br>● Университет музыки и исполнительных искусств Франкфурт-на-Майне<br><br>Studienkollege Франкфурт-на-Майне, с которыми мы работаем<br>● Studienkolleg Франкфурт-на-Майне "
        });
        break; 
      case "b4":
        setModalUniversity({
          title: "Университеты Дюссельдорфа, с которыми мы работаем",
          list: "● Университет Дюссельдорфа им. Генриха Гейне<br>● Университет прикладных наук Дюссельдорфа<br>● Университет прикладных наук Роберта Шумана Дюссельдорф<br>● Академия художеств Дюссельдорфа<br><br>Studienkollege Дюссельдорфа, с которыми мы работаем<br>● Studienkolleg Дюссельдорф (платный)<br>● Studienkolleg Рейнланд (платный)"
        });
        break;   
      case "b5":
        setModalUniversity({
          title: "Университеты Нюрберга, с которыми мы работаем",
          list: "● Университет Фридриха-Александра Эрлангена Нюрберга<br>● Высшая техническая школа им. Георга-Симона-Ома Нюрнберг<br>● Академия изобразительных искусств Нюрнберга<br>● Университет музыки Нюрнберг<br><br>Studienkollege Нюрберга, с которыми мы работаем<br>● Studienkolleg Нюрнберг"
        });
        break;     
      case "b6":
        setModalUniversity({
          title: "Университеты Фрайбурга, с которыми мы работаем",
          list: "● Фрайбургский университет Альберта-Людвига<br>● Педагогический университет Фрайбург<br><br>Studienkollege Фрайбурга, с которыми мы работаем<br>● Albert-Ludwig-Universität Freiburg<br>● Pädagogische Universität Freiburg"
        });
        break; 
      case "b7":
        setModalUniversity({
          title: "Университеты Гамбурга, с которыми мы работаем",
          list: "● Гамбургский университет<br>● Гамбургский университет «ХафенСити»<br>● Технический университет Гамбурга<br>● Академия полиции Гамбурга<br>● Гамбургский военный университет им. Гельмута<br>● Гамбургский университет прикладных наук<br>● Профессиональная академия Гамбурга<br>● Северо-Германская академия финансов и налогового права Гамбург<br>● Университет изобразительных искусств Гамбург<br>● Университет музыки и театра Гамбург<br><br>Studienkollege Гамбурга, с которыми мы работаем<br>● Studienkolleg Гамбург"
        });
        break;    
      case "b8":
        setModalUniversity({
          title: "Университеты Кёльна, с которыми мы работаем",
          list: "● Кёльнский университет<br>● Международная киношкола Кельн<br>● Университет музыки и танца Кельн<br>● Университет физкультуры и спорта Кельн<br>● Академия медиаискусства Кельн<br>● Высшая техническая школа Кёльна<br>● Кельнская международная школа дизайна<br><br>Studienkollege Кёльна<br>● Studienkolleg Кёльн<br>● Studienkolleg при Кёльнской Бизнес-Школе"
        });
        break;   
      case "b9":
        setModalUniversity({
          title: "Университеты Бонна, с которыми мы работаем",
          list: "● Боннский университет Фридриха Вильгельма<br>● Высшая Школа финансов и менеджмента<br><br>Studienkollege Бонна, с которыми мы работаем<br>● Studienkolleg Штайнке Институт (платный)<br>● Studienkolleg Доктора Альтана (платный)<br>● Рейнский Studienkolleg (платный)"
        });
        break;      
      case "b10":
        setModalUniversity({
          title: "Университеты Дрездена, с которыми мы работаем",
          list: "● Технический университет Дрездена TU<br>● Саксонская государственная профессиональная академия Дрездена Berufsakademie Sachsen/Dresden<br>● Евангелическая высшая школа Дрездена EHS<br>● Университет изобразительных искусств Дрездена<br>● Академия гостиничного бизнеса Дрездена SRH<br>● Университет техники и экономики Дрездена HTW<br>● Палукский университет танца в Дрездене<br>● Университет церковной музыки Дрездена<br>● Музыкальный университет Дрездена<br>● Международный университет Дрездена (частный)<br>● Университет прикладных наук Дрездена (частный)<br><br>Studienkollege Дрездена, с которыми мы работаем<br>● Studienkolleg Дрезден (частный)<br>● Studienkolleg Унипарк Дрезден (частный)"
        });
        break;                    
    }
    setIsOpen(true);
  }

  return (
    <>
    <Seo 
      titleTxt="Полезная информация. Университеты Германии. Получение студенческой визы Германия. Переезд в Германию."
      description="Вся полезная информация по поступлению и переезду в Германию. Университеты Германии. Ответы на вопросы."
    />

    <PageTitle 
      firstTitle="Образование"
      secondTitle="за&nbsp;рубежом"
      textAlign='center'
    />

    <section className="information">
        <div className="information_container">
          <div className="information_wrapper">
            <div className="information_content">
              <Link className="information_content_item" to="university" smooth={true} offset={-180}>Университеты и программы</Link>
              <Link className="information_content_item" to="important" smooth={true} offset={-180}>Важное для поступающих</Link>
              <Link className="information_content_item" to="parents" smooth={true} offset={-180}>Для родителей</Link>
              <Link className="information_content_item" to="plus" smooth={true} offset={-180}>Плюсы жизни в Германии</Link>
              <Link className="information_content_item" to="faq" smooth={true} offset={-180}>Часто задаваемые вопросы</Link>
            </div>
          </div>
        </div>
    </section>

    <section className="nav">
      <div className="nav_container">
        <div className="nav_wrapper">
          <div className="nav_right">
              <div className="nav_right_wrapper">
                  <h2 className="nav_right_title">Образование должно быть доступным для всех!</h2>
                  <h3 className="nav_right_subtitle">Германия - лучшие условия для поступления в Европе!</h3>
                  <div className="nav_right_items">
                      <div className="nav_right_item">
                          <div className="nav_right_item_icon">
                              <img src={icon1} alt="denkart-языковая школа" className="nav_right_item_icon_img"/>
                          </div>
                          <span className="nav_right_item_text">Качество</span>
                      </div>
                      <div className="nav_right_item">
                          <div className="nav_right_item_icon">
                              <img src={icon2} alt="denkart-языковая школа" className="nav_right_item_icon_img"/>
                          </div>
                          <span className="nav_right_item_text">Стоимость</span>
                      </div>
                      <div className="nav_right_item">
                          <div className="nav_right_item_icon">
                              <img src={icon3} alt="denkart-языковая школа" className="nav_right_item_icon_img"/>
                          </div>
                          <span className="nav_right_item_text">Престиж</span>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section className="main-information" id="parents">
      <div className="main-information_container">
        <div className="main-information_wrapper">
          <h1 className="main-information_title">Для родителей</h1>
          <p className="main-information_text">Ваш ребенок заканчивает школу и Вы уже задумываетесь, где же он будет учиться дальше?
            Давайте разберемся:<br/><br/>Устойчивое мнение гласит, что учеба за границей - это очень дорого, но давайте же перейдем
            к фактам:</p>
          <p className="main-information_text_center">Учеба за рубежом бывает даже дешевле, чем в Вашем городе</p>
          <span className="main-information_text_price"><a href="/offers-germany">Образование в Германии</a> бесплатное для всех студентов</span>
          <div className="main-information_content">
            <div className="main-information_content_item">
              <div className="main-information_content_item_icon">
                <img src={checkIcon} alt="Немецкое образование" className="main-information_content_item_icon_img" />
              </div>
              <div className="main-information_content_item_info">
                <h1 className="main-information_content_item_info_title">Немецкое образование - <span className="main-information_orange">гарантия</span>
                  успешного будущего Вашего ребенка!</h1>
                <span className="main-information_content_item_info_text">Не стоит недооценивать ещё один немаловажный критерий:
                  дальнейшие перспективы. В Германии очень ценятся профессионалы,
                  имеющие местное образование и так же открывается рынок труда соседних немецкоговорящих стран, таких как
                  Австрия,
                  Швейцария, Люксембург.</span>
              </div>
            </div>
            <div className="main-information_content_item">
              <div className="main-information_content_item_icon">
                <img src={checkIcon} alt="Немецкое образование" className="main-information_content_item_icon_img" />
              </div>
              <div className="main-information_content_item_info">
                <h1 className="main-information_content_item_info_title">Немецкие университеты постоянно входят в топ <span
                    className="main-information_orange">по всему миру</span>.</h1>
                <span className="main-information_content_item_info_text">Учащимся предлагается широкий перечень разнообразных
                  специализаций. Особое внимание стоит уделить учебе по обмену, тем самым студент сможет сменить
                  обстановку и поехать учиться в другую страну, что является очень захватывающим и интересным.<br/><br/>Даже
                  сейчас, во время пандемии, поступление в университеты осуществляется в нормальном режиме. С летнего
                  семестра 2022 года занятия вновь проводятся лично, а не онлайн.</span>
              </div>
            </div>
            <div className="main-information_content_item">
              <div className="main-information_content_item_icon">
                <img src={checkIcon} alt="Немецкое образование" className="main-information_content_item_icon_img"/>
              </div>
              <div className="main-information_content_item_info">
                <h1 className="main-information_content_item_info_title">В Германии учится и живет огромное количество <span
                    className="main-information_orange">иностранцев</span></h1>
                <span className="main-information_content_item_info_text">что дает возможность интернациональному общению. У Вашего
                  ребенка появится много друзей с разных уголков земли.<br/>Лекции и практические занятия проводятся так,
                  что все годы учебы студенты ведут полноценный образ жизни – ездят на каникулах домой, отдыхают на
                  горнолыжных курортах и море, посещают картинные галереи, известные музеи, природные заповедники,
                  путешествуют по городам и странам Европейского союза.</span>
              </div>
            </div>
            <div className="main-information_content_item">
              <div className="main-information_content_item_icon">
                <img src={checkIcon} alt="Немецкое образование" className="main-information_content_item_icon_img" />
              </div>
              <div className="main-information_content_item_info">
                <h1 className="main-information_content_item_info_title">В мировых рейтингах Германия <span className="main-information_orange">одна из
                    лучших</span> стран для проживания.</h1>
                <span className="main-information_content_item_info_text">На жилье студент будет тратить примерно 400 евро, страховка 60,
                  еда в районе 150-200 евро (в зависимости от того: как и где питаться) и личные нужды.</span>
              </div>
            </div>
            </div>
            <p className="main-information_text_2"><span className="main-information_orange">Высшее образование в Германии - это престижное, качественное и
            доступное образование, которое признается не только в Европе, но и во всем мире.</span> Поступить в немецкие
            университеты сразу после школы нельзя, нужно пройти специальный подготовительный курс – Studienkolleg, но не
            стоит пугаться, этот курс очень хорошо поможет вам в адаптации, и мы сможем вам помочь со всеми сложностями на
            пути.</p>
          </div>
      </div>
    </section>

    <section className="tabs">
      <div className="tabs_container">
        <div className="tabs_wrapper">
          <h1 className="tabs_title">Почему вы можете нам довериться?</h1>
          <p className="tabs_text">Выберите путь ниже и посмотрите, какие этапы вас ожидают</p>
          <div className="tabs_btns">
            <div className="tabs_btn-denkart">
              <h1 className="tabs_btn-denkart_title">DenkArt</h1>
              <span className="tabs_btn-denkart_text">Выбирая нас, Вы оберегаете себя от<br/>всевозможных рисков</span>
              <input className="tab tabs_btn-denkart_btn" type="button" value="Посмотреть" data-target="#denkart" onClick={()=> setStateTabs(false)}/>
            </div>
            <div className="tabs_btn-sam">
              <h1 className="tabs_btn-sam_title">Всё сам</h1>
              <span className="tabs_btn-sam_text">С чем я столкнусь, если<br/>буду делать всё сам?</span>
              <input className="tab tabs_btn-sam_btn" type="button" value="Посмотреть" data-target="#sam" onClick={()=> setStateTabs(true)}/>
            </div>
          </div>
          <div className="tabs_items">
            <div className={!stateTabs ? "tabs_tab visible" : "tabs_tab"} id="denkart">
              <div className="tabs_tab_item">
                <img src={checkMark} alt="Выбор DenkArt" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Поступление в университет</h1>
                  <span className="tabs_tab_item_text">Гарантируем получение приглашения от выбранного университета</span>
                </div>
              </div>
              <div className="tabs_tab_item">
                <img src={checkMark} alt="Выбор DenkArt" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Оформление студенческой визы (ВНЖ)</h1>
                  <span className="tabs_tab_item_text">Успешная подача документов в срок</span>
                </div>
              </div>
              <div className="tabs_tab_item">
                <img src={checkMark} alt="Выбор DenkArt" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Поиск студенческого жилья</h1>
                  <span className="tabs_tab_item_text">Успешное бронирование жилья на весь период обучения</span>
                </div>
              </div>
              <div className="tabs_tab_item">
                <img src={checkMark} alt="Выбор DenkArt" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Адаптация за рубежом</h1>
                  <span className="tabs_tab_item_text">Помощь в оформлении и получении документов первой
                    важности</span>
                </div>
              </div>
            </div>
            <div className={stateTabs ? "tabs_tab visible" : "tabs_tab"} id="sam">
              <div className="tabs_tab_item">
                <img src={checkNotMark} alt="Самостоятельный выбор" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Поступление в университет</h1>
                  <span className="tabs_tab_item_text">Отказ от всевозможных университетов Австрии</span>
                </div>
              </div>
              <div className="tabs_tab_item">
                <img src={checkNotMark} alt="Самостоятельный выбор" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Оформление студенческой визы (ВНЖ)</h1>
                  <span className="tabs_tab_item_text">Пропуск установленного срока для подачи документов</span>
                </div>
              </div>
              <div className="tabs_tab_item">
                <img src={checkNotMark} alt="Самостоятельный выбор" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Поиск студенческого жилья</h1>
                  <span className="tabs_tab_item_text">Постоянные переезды из-за некомфортных условий проживания</span>
                </div>
              </div>
              <div className="tabs_tab_item">
                <img src={checkNotMark} alt="Самостоятельный выбор" className="tabs_tab_item_icon"/>
                <div className="tabs_tab_item_texts">
                  <h1 className="tabs_tab_item_title">Адаптация за рубежом</h1>
                  <span className="tabs_tab_item_text">Нехватка знаний в структуре работы государственных органов Австрии</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FreeConsultation 
      isGermany="1"
    />

    <section className="swiper-information_container" id="plus">
      <Swiper
        className="swiper-information" 
        id="plus"
        navigation={true} 
        modules={[Navigation]}
        loop={true}
      >
        <SwiperSlide className="swiper-information_slide">
          <div className="swiper-information_slide_photo swiper-information_slide_photo_4"></div>
          <div className="swiper-information_slide_texts">
            <h1 className="swiper-information_slide_title">Высшее образование в Германии - это престижно, качественно и доступно</h1>
            <p className="swiper-information_slide_text">Немецкий диплом признаётся не только в Европе, но и во всём мире. Поступить в
              немецкие университеты не так сложно, как кажется на первый взгляд, но следует хорошо подготовиться и это
              очень хорошая возможность получить интересный опыт с
              дальнейшими перспективами.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-information_slide">
          <div className="swiper-information_slide_photo swiper-information_slide_photo_5"></div>
          <div className="swiper-information_slide_texts">
            <h1 className="swiper-information_slide_title">В мировых рейтингах Германия занимает ведущие позиции.</h1>
            <p className="swiper-information_slide_text">Германия занимает особую позицию практически во всех сферах жизни. Со своей
              экономической и социальной структурой, благоприятным климатом и выгодным территориальным положением.
              Многие студенты мечтают об учебе в этой развитой стране.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-information_slide">
          <div className="swiper-information_slide_photo swiper-information_slide_photo_6"></div>
          <div className="swiper-information_slide_texts">
            <h1 className="swiper-information_slide_title">Гибкая система и модульный принцип учебного процесса</h1>
            <p className="swiper-information_slide_text">позволит вам ощутить систему образования всех ведущих университетов мира, где
              каждый студент сам определяет своё расписание и специализацию, выбирает преподавателей и сам назначает
              себе экзамены. Так же учёба в Германии позволяет студенту ощутить все прелести европейской жизни.</p>
          </div>
        </SwiperSlide>

      </Swiper>
    </section>

    <section className="video">
      <div className="video_container">
        <div className="video_wrapper">
          <iframe width="1000" height="500" src="https://www.youtube.com/embed/Q-uJV0YzgZk" title="YouTube video player"
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
      </div>
    </section>

    <section className="next">
      <div className="next_container">
        <div className="next_wrapper">
          <span className="next_title">Дальнейшие перспективы:</span>
          <p className="next_text">
          После сдачи выпускного экзамена, каждый студент имеет право заниматься трудоустройством до 18
          месяцев.<br/><br/>У всех, кто хочет остаться в Германии, есть отличный шанс через несколько лет получить
          немецкое гражданство и найти
          хорошо оплачиваемую работу. В стране стабильная процветающая экономика, высокие социальные стандарты и
          достойные
          зарплаты.<br/><br/>Очень хорошее медицинское обеспечение. Германия является одной из ведущих стран в качестве
          медицинского обслуживания, высокая квалификация врачей и медицинского оборудования. Все проживающие на
          территории Германии достаточно долгое время получают врачебную помощь бесплатно.</p>
        </div>
      </div>
    </section>

    <section className="univers" id="university">
      <div className="univers_container">
        <div className="univers_wrapper">
          <div className="univers_item univers_item_6" onClick={()=>handleOpenModal("b1")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Берлин</h1>
            </div>
          </div>
          <div className="univers_item univers_item_7" onClick={()=>handleOpenModal("b2")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Мюнхен</h1>
            </div>
          </div>
          <div className="univers_item univers_item_8" onClick={()=>handleOpenModal("b3")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Франкфурт-на-Майне</h1>
            </div>
          </div>
          <div className="univers_item univers_item_9" onClick={()=>handleOpenModal("b4")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Дюссельдорф</h1>
            </div>
          </div>
          <div className="univers_item univers_item_10" onClick={()=>handleOpenModal("b5")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Нюрберг</h1>
            </div>
          </div>
        </div>
        <div className="univers_wrapper">
          <div className="univers_item univers_item_11" onClick={()=>handleOpenModal("b6")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Фрайбург</h1>
            </div>
          </div>
          <div className="univers_item univers_item_12" onClick={()=>handleOpenModal("b7")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Гамбург</h1>
            </div>
          </div>
          <div className="univers_item univers_item_13" onClick={()=>handleOpenModal("b8")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Кёльн</h1>
            </div>
          </div>
          <div className="univers_item univers_item_14" onClick={()=>handleOpenModal("b9")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Бонн</h1>
            </div>
          </div>
          <div className="univers_item univers_item_15" onClick={()=>handleOpenModal("b10")}>
            <div className="univers_item_title">
              <div className="univers_item_icon"></div>
              <h1 className="univers_item_name">Дрезден</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="important" id="important">
      <div className="important_container">
        <div className="important_wrapper">
          <h1 className="important_title">Важное для поступающих</h1>
          <div className="important_content">
            <div className="important_content_left">
              <p className="important_text">Подать документы в вуз можно <span className="main-information_orange">2 раза в год</span>: перед началом
                зимнего и летнего семестра.<br/><br/>Дедлайн на <span className="main-information_orange">зимний семестр – до 15 июля</span>, на <span className="main-information_orange">летний – до 15 января</span>.<br/><br/>Для зарубежных студентов действует ряд обязательных
                правил:</p>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">копия паспорта;</span>
              </div>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">аттестат об окончании средней школы или диплом о высшем образовании, если выпускник поступает после бакалавриата или магистратуры;</span>
              </div>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">языковой сертификат, подтверждающий знание немецкого или
                английского языка на высоком уровне, если абитуриент сразу поступает в университет;</span>
              </div>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">подтверждение, что абитуриент владеет базовыми знаниями
                немецкого на уровне B1, если он идёт на подготовительное отделение;</span>
              </div>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">автобиография;</span>
              </div>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">мотивационное письмо;</span>
              </div>
              <div className="important_content_left_item">
                <div className="important_content_left_item_icon"></div>
                <span className="important_content_left_item_text">рекомендательные письма.</span>
              </div>
            </div>
            <div className="important_content_right">
              <img src={importantImg} alt="Важное для поступления" className="important_content_right_img" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="social">
      <div className="social_container">
        <div className="social_wrapper">
          <div className="social_title">Решили самостоятельно заняться поступлением?<br/>Тогда не упустите самую актуальную
            информацию<br/>и подписывайтесь на нас в социальных сетях!</div>
          <div className="social_content">
            <a href="https://instagram.com/denkart_academy" rel="noreferrer" className="social_link" target="_blank">
              <img src={social1} alt="Наш Instagram" className="social_link_img" />
            </a>
            <a href="https://vk.com/club210644128" rel="noreferrer" className="social_link" target="_blank">
              <img src={social2} alt="Наш ВК" className="social_link_img" />
            </a>
            <a href="https://t.me/denkart_da" rel="noreferrer" className="social_link" target="_blank">
              <img src={social3} alt="Наш Телеграм" className="social_link_img" />
            </a>
            <a href="https://youtube.com/channel/UCXVUavN9-TgMPsK8ya6twew" target="_blank" className="social_link">
                <img src={youtubeIcon} alt="Youtube DenkArt" className="social_link_img" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="questions" id="faq">
      <div className="questions_container">
        <h1 className="questions_title">Часто задаваемые вопросы</h1>
        <div className="questions_wrapper">
          <Details 
            summary='Какие сроки подачи документов?'
            text='Подать документы в университеты Германии можно два раза в год: перед началом
            зимнего и летнего семестра. Дедлайн на
            зимний семестр – до 15 июля, на летний – до 15 января.'
          />
          <Details 
            summary="Какие основные требования поступления в университеты Германии?"
            text="Чтобы поступить в университеты Германии важен факт наличия 12-13 лет
            образования на Родине. Т.е. можно поступить в немецкий ВУЗ только после 1 курса учёбы на дневном отделении в
            аккредитованном Вузе. Это может быть вуз на родине или в
            другой стране, например, в Австрии."
          />
          <Details 
            summary="Как влияют оценки школьного аттестата (или диплома колледжа, университета)
            на поступление?"
            text="Оценки в аттестате роли не играют. Во внимание принимают оценки из
            университета. Обычно у университета или есть минимальные требования по уровню диплома или оценки играют роль
            только в случае возникновения конкурса. Также сильное
            влияние оказывают оценки из Studienkolleg."
          />
          <Details 
            summary="Существуют ли вступительные тесты при записи в Университет?"
            text="На большинство факультетов не предусмотрено вступительных тестов, за
            исключением языкового теста. На творческие специальности, медицину, а также, некоторые популярные факультеты
            может быть введен конкурс, на основании экзаменов,
            работ и т.д."
          />
          <Details 
            summary="Что такое «Подготовительная программа немецких университетов?»"
            text="Подготовительная программа при университете –
            Studienkolleg.<br/><br/>Studienkolleg является обязательным для тех, кто хочет поступать в немецкий ВУЗ и не
            удовлетворяет условиям для «прямого» поступления. Например, после 11 лет школьного образования на родине.
            Срок обучения в Studienkolleg – 2 семестра.<br/><br/>В конце курса «выпускной экзамен» – Feststellungsprüfung.
            Успешная сдача оного подразумевает вашу готовность к дальнейшей учебе на бакалавриате."
          />
          <Details 
            summary="Есть ли возможность обучения на английском языке в университетах Германии?"
            text="Да, есть программы обучения на английском, но их не так много, как программ на немецком."
          />
          <Details 
            summary="Какая разница между бакалавром и магистром?"
            text="Бакалавр и магистр – две ступени высшего образования университета. Сначала
            студент учится на бакалавра, потом на магистра. Бакалавр длится 3-4 года.<br><br>
            Магистратура длится 2 года. Поступление возможно только на основе диплома о высшем образовании, и только по
            тому же
            направлению, по которому у вас получен диплом на Родине."
          />
          <Details 
            summary="Платное ли образование в государственных университетах Германии?"
            text="Оплаты за обучение нет. Однако есть небольшие сборы со студентов, которые
            составляют 100-500 евро в семестр в зависимости от земли, которые также покрывают оплату за проезд на все
            виды транспорта."
          />
          <Details 
            summary="Имеет ли право работать иностранный студент во время обучения?"
            text="Студенческая виза дает право работать 120 полных или 240 неполных рабочих дней
            в году. Что составляет 20 рабочих часов в неделю. Неполным рабочим днем считается рабочее время не более 4
            часов."
          />
          <Details 
            summary="Что будет, если провалить вступительный экзамен?"
            text="Если вы не сдадите вступительный экзамен, то повторить его можно будет только в
            следующем году. Готовиться нужно очень внимательно."
          />
          <Details 
            summary="Какая сумма должна быть на счету у студента в Германии?"
            text="Необходимая сумма постоянно меняется и нужно внимательно следить за
            обновлениями. В настоящий момент сумма на счет у студента в Германии, независимо от возраста, должна
            составлять минимум 10 500 евро на год жизни."
          />
          <Details 
            summary="Какие требования к знанию немецкого языка?"
            text="Необходимый для обучения в большинстве университетов Германии уровень владения
            немецким языком – С1, реже В2. Тем не менее, для регистрации в Studienkolleg у вас может быть уровень
            владения языком – В1 и в последующем доучить язык до необходимого уровня."
          />
          <Details 
            summary="Какой возраст должен быть у абитуриентов?"
            text="Для поступления и учебы в университетах Германии возраст не имеет значения."
          />
          <Details 
            summary="Что такое Fachhochschule? В чем отличие от государственных вузов?"
            text="Fachhochschule принято переводить как “Университет Прикладных Наук”. Это такие же учебные заведения высшей категории, как и университеты, где вы можете получить степень бакалавра или магистра.<br/> Основные отличия от университетов:<br/>
            ● Срок обучения фиксированный (в гос.вузах – нет)<br/>
            ● Всегда есть вступительный экзамен<br/>
            ● Отсутствует подготовительное отделение<br/>
            ● Обязательная практика во время учебы<br/><br/>
            Этот вариант отлично подойдет тем абитуриентам, у кого уже есть отличное знание немецкого языка. Тем не менее, конкурс на поступление в таких вузах довольно серьезный."
          />
        </div>
      </div>
    </section>

    <Modal
      open={isOpenModal}
      onClose={() => setIsOpen(false)}
    >
      <Box className="modal-information">
        <div className="modal-information_content_header">
          <h1 className="modal-information_content_title">{modalUniversity.title}</h1>
        </div>
        <span className="modal-information_content_text" dangerouslySetInnerHTML={{ __html: modalUniversity.list }}></span>
      </Box>
    </Modal>
    </>
  )
}

export default InformationGermany;