import PageTitle from "../../components/PageTitle";
import Seo from "../../components/Seo";
import ConsoleText from "./ConsoleText";
import "./style.scss";

import alena from "../../img/information/alena.png";
import { Modal, Box, TextField, Button, LinearProgress } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { closeModal, openModal } from "../../redux/slices/newOrderSlice";

import data from "../../news-school.json";
import FreeConsultation from "../Index/Banners/FreeConsultation";

type FormOrder = {
    application_type: string;
    product: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email_address: string;
    comment: string;
};

const School = () => {
    const [isSending, setIsSending] = useState(false);
    const [isOpenResultDialog, setIsOpenResultDialog] = useState(false);
    const [modalInfo, setModalInfo] = useState("");
    const [newsSchool, setNewsSchool] = useState(data.data);

    const { selectedComplex, country, isOpenModal } = useAppSelector(
        (state: RootState) => state.newOrder
    );
    const dispatch = useAppDispatch();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormOrder>();

    const onSubmit: SubmitHandler<FormOrder> = (data) => {
        setIsSending(true);

        data.application_type = "free_lesson";
        data.product = `${selectedComplex} (${country})`;

        const dataSend = new FormData();
        const config = {
            method: "post",
            url: "https://api2.denkart.academy/api/application",
            data: dataSend,
        };

        dataSend.append("data", JSON.stringify(data));

        axios(config)
            .then(function (response) {
                setIsSending(false);
                setIsOpenResultDialog(true);
                setModalInfo(response.data.message_user);
                reset();
            })
            .catch(function (error) {
                setIsSending(false);
                setIsOpenResultDialog(true);
                console.log(error);

                setModalInfo(error.response.data.message_user);
            });
    };

    const style = {
        input: {
            background: "#fff",
            borderRadius: "10px",
            marginBottom: "20px",
        },
        modal: {
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            outline: "none",
        },
        button: {
            background: "#FFB078",
            color: "#000",
            "&:hover": {
                bgcolor: "#e9a16f", // change the background color on hover
            },
        },
        cancel: {
            border: "1px solid #000",
            color: "#000",
            marginRight: "15px",
        },
    };

    const handleClose = () => {
        reset();
        dispatch(closeModal());
        setIsOpenResultDialog(false);
    };
    return (
        <>
            <Seo
                titleTxt="Немецкий язык. Языковая школа. Курсы немецкого языка. DenkArt."
                description="Изучение немецкого языка онлайн с преподавателями из Австрии и Германии. Индивидуальные и групповые занятия."
            />
            <PageTitle
                firstTitle="Языковая"
                secondTitle="школа"
                textAlign="center"
            />
            <section className="words">
                <div className="words_container">
                    <div className="words_block">
                        <h2 className="words_title">
                            Немецкий язык - это язык инноваций и будущего
                        </h2>
                        <div className="words_wrapper_bg">
                            <div className="words_wrapper">
                                <ConsoleText
                                    words={[
                                        "das Studium",
                                        "die Freiheit",
                                        "die Freude",
                                        "der Spaß",
                                        "die Möglichkeit",
                                        "der Traum",
                                        "der Augenblick",
                                        "die Fähigkeit",
                                        "die Sicherheit",
                                        "der Glück",
                                    ]}
                                    id="text"
                                    colors={[
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                        "white",
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="advantages">
                <div className="advantages_container">
                    <h1 className="advantages_title">
                        Преимущества изучения немецкого языка
                    </h1>
                    <div className="advantages_wrapper">
                        <div className="advantages_item">
                            <div className="advantages_item_header advantages_item_header_1">
                                <h2 className="advantages_item_header_title">
                                    Индивидуальные занятия
                                </h2>
                            </div>
                            <div className="advantages_item_body">
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_1"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Гибкий график
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_1"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Индивидуальный темп обучения
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_1"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Возможность переноса занятий
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_1"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Собственный план изучения
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_1"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Изучение на дому или в офисе
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="advantages_item">
                            <div className="advantages_item_header advantages_item_header_2">
                                <h2 className="advantages_item_header_title">
                                    Мини-группы
                                </h2>
                            </div>
                            <div className="advantages_item_body">
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_2"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Знакомство с новыми людьми
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_2"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Легкое преодоление языкового барьера
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_2"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Приятное времяпровождение
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_2"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Коммуникация с другими людьми
                                    </span>
                                </div>
                                <div className="advantages_item_body_elem">
                                    <div className="advantages_divs_item_icon_2"></div>
                                    <span className="advantages_item_body_elem_text">
                                        Изучение на дому или в офисе
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="advantages_info">
                        <p className="advantages_text">
                            Вы гарантированно заговорите уже{" "}
                            <span className="orange">
                                после первого занятия
                            </span>{" "}
                            и через
                        </p>
                        <span className="advantages_time">2 месяца</span>
                        <p className="advantages_text">
                            начнете самостоятельно строить предложения и
                            общаться на бытовые темы!
                            <br />
                            <br />
                            Занятия строятся на четырех аспектах:{" "}
                            <span className="orange">
                                чтение, письмо, аудирование, говорение
                            </span>
                            . Делается упор на каждый пункт для получения
                            максимального результата, потому что заинтересованы{" "}
                            <span className="orange">в прогрессе</span> наших
                            учеников! Занятия формируют{" "}
                            <span className="orange">
                                любовь&nbsp;к&nbsp;немецкому
                            </span>
                            &nbsp;языку&nbsp;с&nbsp;первого&nbsp;шага.
                        </p>
                    </div>
                </div>
            </section>
            <div className="free-lesson">
                <div className="free-lesson_container">
                    <div className="free-lesson_wrapper">
                        <div className="free-lesson_left">
                            <h1 className="free-lesson_title">
                                Бесплатный пробный урок*
                            </h1>
                            <h2 className="free-lesson_text">
                                Записывайтесь на 30-минутное занятие, чтобы
                                убедиться в качестве занятий.
                            </h2>
                            <h3 className="free-lesson_date">
                                *Предложение доступно для новых учеников
                            </h3>
                        </div>

                        <input
                            className="free-lesson_btn complex_item_footer_btn"
                            type="button"
                            value="Записаться"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        title: "Бесплатный урок",
                                        country: "",
                                    })
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <section className="forward">
                <div className="forward_container">
                    <div className="forward_wrapper">
                        <span className="forward_title">
                            Не знаете свой уровень владения языка?
                        </span>
                        <span className="forward_text">
                            Пройдите тест прямо сейчас онлайн и бесплатно
                        </span>
                        <a
                            href="https://berlinerdeutsch.ru/test"
                            target="_blank"
                            className="forward_form_btn_test freetest">
                            Пройти тест бесплатно
                        </a>
                        <div className="forward_alena">
                            <img
                                src={alena}
                                alt="Образовательный центр DenkArt. Узнать уровень немецкого языка бесплатно."
                                className="forward_alena_img"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="summer" id="camping">
                <div className="summer_container">
                    <div className="summer_wrapper">
                        <p className="summer_text">
                            Думаете это всё?
                            <br />А вот и нет! У нас есть еще одно предложение
                            для вас:
                        </p>
                        <h1 className="summer_title">
                            Летняя языковая школа в Австрии
                        </h1>
                        <p className="summer_text">
                            Отличный вариант провести свои летние каникулы в
                            Австрии и изучить немецкий язык с носителями!
                        </p>
                        <span className="summer_descr">
                            Обучение в летней языковой школе - это{" "}
                            <span className="orange">
                                основание для получения визы в Австрию!
                            </span>{" "}
                            Мы поможем оформить документы на визу, запишем в
                            посольство, при необходимости поможем найти жилье на
                            время обучения в ближайшем студенческом общежитии,
                            сопроводим по приезде в страну и предоставим
                            трансфер.
                        </span>
                        <br /><br />Если Вас интересует более подробная информация оставьте заявку ниже и мы свяжемся с вами.
                    </div>
                </div>
            </section>
            <FreeConsultation isGermany="" />
            <div className="news">
                <div className="news_container">
                    <div className="news_wrapper">
                        {newsSchool.map((item) => {
                            return (
                                <div className="news_item news_item_two">
                                    <div
                                        className="news_item_background"
                                        style={{
                                            background: `url(${item.link_photo})`,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                        }}></div>
                                    <h3 className="news_item_title">
                                        {item.title}
                                    </h3>
                                    <span className="news_item_text">
                                        {item.text}
                                    </span>
                                    <a href={item.link}>Посмотреть</a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Modal open={isOpenModal} onClose={handleClose}>
                <Box className="modal-order">
                    <div
                        className="modal-order_wrapper"
                        style={isOpenResultDialog ? { display: "none" } : {}}>
                        <h1 className="modal-order_title">Оформить заказ</h1>
                        <p className="modal-order_item">
                            Вы выбрали <b>пакет {selectedComplex}</b>. Оставьте
                            пожалуйста свои контактные данные и наш менеджер
                            свяжется с Вами
                        </p>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="modal-order_form">
                            <div className="mmodal-order_form_contact">
                                <input
                                    type="hidden"
                                    name="product"
                                    id="product"
                                    value="Пакет NULL"
                                />
                                <TextField
                                    size="small"
                                    label="Ваше имя"
                                    fullWidth
                                    {...register("first_name", {
                                        required: true,
                                    })}
                                    error={!!errors.first_name}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                                <TextField
                                    size="small"
                                    label="Ваша фамилия"
                                    fullWidth
                                    {...register("last_name", {
                                        required: true,
                                    })}
                                    error={!!errors.last_name}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                                <TextField
                                    size="small"
                                    label="Контактный номер телефона"
                                    fullWidth
                                    {...register("phone_number", {
                                        required: true,
                                    })}
                                    error={!!errors.phone_number}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                                <Controller
                                    name="email_address"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Invalid email format",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            size="small"
                                            fullWidth
                                            label="Email"
                                            variant="outlined"
                                            {...register("email_address", {
                                                required: true,
                                            })}
                                            error={!!errors.email_address}
                                            InputProps={{
                                                style: style.input,
                                            }}
                                        />
                                    )}
                                />
                                <TextField
                                    size="small"
                                    label="Комментарий (необязательно)"
                                    fullWidth
                                    {...register("comment")}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                            </div>

                            <div className="modal-order_btns">
                                <Button
                                    sx={style.cancel}
                                    type="submit"
                                    variant="outlined"
                                    onClick={() => handleClose()}>
                                    Отменить
                                </Button>
                                <Button
                                    sx={style.button}
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}>
                                    Отправить
                                </Button>
                                {isSending && (
                                    <LinearProgress
                                        color="secondary"
                                        style={{ marginTop: "10px" }}
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                    <div
                        className="modal-order_wrapper"
                        style={
                            isOpenResultDialog
                                ? { display: "flex" }
                                : { display: "none" }
                        }>
                        <h1 className="modal-order_title">
                            Спасибо! Заявка отправлена!
                        </h1>
                        <p className="modal-order_item" id="final-text">
                            {modalInfo}
                        </p>
                        <Button
                            sx={style.button}
                            type="submit"
                            variant="contained"
                            onClick={() => handleClose()}>
                            Спасибо, жду
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default School;
