import { Typography, Rating, TextField } from '@mui/material';
import React, { useState } from 'react'
import BtnSend from '../../components/Btn';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

import './style.scss';

type FormOpinion = {
    first_name: string,
    email_address: string,
    rating: number | null,
    comment: string,
    application_type: string
};


const Opinion = () => {
const [value, setValue] = React.useState<number | null>(5);

    const [ isSending, setIsSending ] = useState(false);
    const [ isOpenResultDialog, setIsOpenResultDialog ] = useState(false);
    const [ modalInfo, setModalInfo ] = useState("");

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormOpinion>();

    const handleCloseResultDialog = () => {
        setIsOpenResultDialog(false);
    }

    const onSubmit: SubmitHandler<FormOpinion> = (data) =>  {

        setIsSending(true);

        data.application_type = "new_opinion";
        data.rating = value;

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://api2.denkart.academy/api/application',
            data : dataSend
        };

        dataSend.append('data', JSON.stringify(data));

        axios(config)
        .then(function (response) {
            setIsSending(false);
            setIsOpenResultDialog(true);
            setModalInfo(response.data.message_user);
            reset();
        })
        .catch(function (error) {
            setIsSending(false);
            setIsOpenResultDialog(true);
            console.log(error);
            
            setModalInfo(error.response.data.message_user);
        });
    }


  return (
    <>
    <section className="modal">
        <div className="modal_content">
        <h1 className="modal_title">Нам важно услышать Ваше мнение о нас.</h1>
        <p className="modal_item">Оставьте комментарий, касаемый работы с нами и получите подарок за помощь в улучшении нашей Академии.</p>
        <form action="#" method="post" className="modal_form" id="opinion_form">
            <TextField
                label="Ваше имя"
                size="small"
                style={{marginBottom: "15px"}}
                {...register("first_name", { required: true })}
                error={!!errors.first_name}
            />
            <TextField
                label="Email адрес, на который выслать подарок"
                size="small"
                style={{marginBottom: "15px"}}
                {...register("email_address", { required: true })}
                error={!!errors.email_address}
            />
            <Typography component="legend">Поставьте общую оценку</Typography>
            <Rating
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                style={{marginBottom: "15px"}}
            />
            <TextField
                label="Комментарий"
                multiline
                rows={4}
                size="small"
                style={{marginBottom: "15px"}}
                {...register("comment", { required: true })}
                error={!!errors.comment}
            />
            <BtnSend 
                label="Отправить"
                handler={handleSubmit(onSubmit)}
            />
        </form>
        </div>
    </section>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSending}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Dialog
        open={isOpenResultDialog}
        onClose={handleCloseResultDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {modalInfo}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseResultDialog} autoFocus>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default Opinion