import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide, Box, Modal, TextField, LinearProgress } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { closeModal, nextStep } from '../../redux/slices/checkOddsSlice';
import freeConsSendIcon from '../../img/index/otpravit-zayavku.svg';

import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

import './quiz.scss';

type FormConsultation = {
    first_name: string,
    email_address: string,
    phone_number: string,
    application_type: string,
};

const dataQuestions = [
	{
		fullText: "Кто заполняет анкету?",
		shortText: "user",
		answers: [
			"Абитуриент",
			"Родитель"
		]
	},
	{
		fullText: "Какая страна для поступления интересует?",
		shortText: "country",
		answers: [
			"Австрия",
			"Германия"
		]
	},
	{
		fullText: "Какая степень образования интересует?",
		shortText: "step",
		answers: [
			"Бакалавр",
			"Дипломная специальность",
			"Магистратура",
			"Врач-специалист",
			"Докторантура"
		]
	},
	{
		fullText: "Когда планируете начать учёбу?",
		shortText: "start",
		answers: [
			"01.09.2024",
			"01.03.2025",
            "01.09.2025",
            "01.03.2026"
		]
	},
	{
		fullText: "На каком языке хотели бы учиться?",
		shortText: "lang",
		answers: [
			"Немецкий",
			"Английский",
			"Без разницы"
		]
	},
	{
		fullText: "Уровень знаний немецкого языка:",
		shortText: "levelDe",
		answers: [
			"А0",
			"А2",
			"В1",
			"В2-С1"
		]
	},
	{
		fullText: "Уровень знаний английского языка:",
		shortText: "levelEng",
		answers: [
			"А0",
			"А2",
			"В1",
			"В2-С1"
		]
	},
	{
		fullText: "На учебу и проживание в Австрии/Германии я планирую тратить:",
		shortText: "money",
		answers: [
			"до 800 евро в месяц",
			"1 250 - 1 500 евро в месяц",
			"больше 1 500 евро в месяц",
			"850 - 1 200 евро в месяц",
			"до 500 евро в месяц"
		]
	}
];


const ModalOdds = () => {
    
    const { isOpenModal } = useAppSelector((state: RootState) => state.checkOdds);
    const [step, setStep] = useState({
        current: 0,
        max: 8,
        isSending: false,
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormConsultation>();
    const dispatch = useAppDispatch();
    const [answers, setAnswers] = useState<{ key: string, value: string }[]>([]);

    const handleNextStep = (ques:string, answer:string) => {
        if(step.current < step.max ) {
            setAnswers([...answers, {key: ques, value: answer}]);
        } 
        setStep({
            ...step,
            current: step.current+1
        });
    }

    const getQues = () => {
        if(step.current < step.max ) {
            const item = dataQuestions[step.current];
            return(
                <div className="check-odds_slide">
                    <span className="check-odds_slide_question">{item.fullText}</span>
                    <div className="check-odds_slide_btns">
                        {item.answers.map((answer, index)=>{
                            return(<label key={answer+index} className="check-odds_slide_btn" onClick={()=>handleNextStep(item.fullText, answer)}>
                                        <input type="radio" value={answer} />
                                        <span className="checkmark">{answer}</span>
                                    </label>)
                        })}
                        
                    </div>
                </div>
            )
        } else {
            return null;
        }

        
    }

    const onSubmit: SubmitHandler<FormConsultation> = (data) =>  {

        setStep({
            ...step,
            isSending: true
        });

        data.application_type = "check_odds";

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://api2.denkart.academy/api/application',
            data : dataSend
        };

        dataSend.append('data', JSON.stringify(data));
        dataSend.append('answers', JSON.stringify(answers));

        axios(config)
        .then(function (response) {
            setStep({
                ...step,
                current: 9,
                isSending: false
            });
            reset();
        })
        .catch(function (error) {
            alert(error);
        });
    }

    return (
        <Modal
            open={isOpenModal}
            onClose={() => dispatch(closeModal())}
        >
            <Box className="check-odds">
                <div className="check-odds_container">
                    <div className="check-odds_content">
                        <div className="check-odds_header">
                            <div className="check-odds_close" onClick={()=>dispatch(closeModal())}></div>
                            <h2>Проверить шансы на поступление</h2>
                        </div>
                        <div className="check-odds_body">
                            {getQues()}
                            <form onSubmit={handleSubmit(onSubmit)} className="contact-form" style={step.current == 8 ? {display: "flex"}: {display: "none"}}>
                                <TextField 
                                    size='small'
                                    label="Ваше имя"
                                    fullWidth
                                    {...register("first_name", { required: true })}
                                    error={!!errors.first_name}
                                    InputProps={{
                                        style: {
                                            background: "#fff",
                                            borderRadius: '10px', // Set the border radius value here
                                            marginBottom: "8px"
                                        },
                                    }}
                                />
                                <TextField 
                                    size='small'
                                    label="Email адрес"
                                    fullWidth
                                    {...register("email_address", { required: true })}
                                    error={!!errors.email_address}
                                    InputProps={{
                                        style: {
                                            background: "#fff",
                                            borderRadius: '10px', // Set the border radius value here
                                            marginBottom: "8px"
                                        },
                                    }}
                                />
                                <TextField 
                                    size='small'
                                    label="Номер телефона"
                                    fullWidth
                                    {...register("phone_number", { required: true })}
                                    error={!!errors.phone_number}
                                    InputProps={{
                                        style: {
                                            background: "#fff",
                                            borderRadius: '10px', // Set the border radius value here

                                        }
                                    }}
                                />
                                <span className="check-odds_contact_item_text">*где есть Viber, WhatsApp, Telegram</span>
                                <button className="contact-form_submit" onClick={() => onSubmit}>
                                    <img src={freeConsSendIcon} alt="Образовательный центр бесплатное первое занятие" className="contact-form_submit_icon" />
                                    <span>Отправить заявку</span>
                                </button>
                                
                            </form>
                            { step.isSending && <LinearProgress 
                            color="secondary"
                            style={{marginTop: "10px", width: "200px"}}
                            /> }
                        </div>
                        <div className="check-odds_final" style={step.current == 9 ? {display: "flex"}: {display: "none"}}>
                            <span className="check-odds_final_text">Спасибо!<br/>Наш специалист свяжется с Вами в ближайшее время и<br />проинформирует Вас о шансах на поступление.</span>
                        </div>
                        <div className="check-odds_footer" style={step.current < 8 ? {display: "block"}: {display: "none"}}>
                            <div className="check-odds_footer_indicator">
                                <div className="check-odds_footer_indicator_all"></div>
                                <div className="check-odds_footer_indicator_done" style={{width: (step.current/(step.max+1))*100 + '%'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalOdds;