import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import checkOddsSlice from '../redux/slices/checkOddsSlice';
import newOrderSlice from '../redux/slices/newOrderSlice';

export const store = configureStore({
  reducer: {
    newOrder: newOrderSlice,
    checkOdds: checkOddsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
