import React from 'react'
import TeamItem from './TeamItem';


import worker0 from '../../../img/about/o-nas-alena-chumraeva-yankovskaya.jpg';
import worker1 from '../../../img/about/team/1.png';
import worker2 from '../../../img/about/team/2.jpg';
import worker3 from '../../../img/about/team/3.jpg';
import worker4 from '../../../img/about/team/8.jpg';
import worker5 from '../../../img/about/team/5.jpg';
import worker6 from '../../../img/about/team/6.jpg';
import worker9 from '../../../img/about/team/9.jpg';
import worker10 from '../../../img/about/team/10.jpg';
import worker11 from '../../../img/about/team/11.jpg';

const Team = () => {
  return (
    <div className="team">
        <div className="team_wrapper">
            <h1 className="team_title">Наша команда</h1>
            <div className="team_director">
                <div className="team_director_container">
                    <p className="team_director_say_mobile">
                        Команда специалистов DenkArt крепко убеждены, что
                        “тот, кто идёт – обязательно дойдет, ведь дорогу осилит идущий”,
                        а мы добавим в этот путь положительные эмоции.
                    </p>
                    <div className="team_director_photo">
                        <img src={worker0} alt="Образовательный центр DenkArt Директор Алёна Чумраева-Янковская" className="team_director_photo_img" />
                    </div>
                    <div className="team_director_right">
                        <span className="team_director_say">
                            Команда специалистов DenkArt крепко убеждены, что
                            “тот, кто идёт – обязательно дойдет, ведь дорогу осилит идущий”,
                            а мы добавим в этот путь положительные эмоции.
                        </span>
                        <div className="team_director_text">
                            <span className="team_director_name">Алёна Чумраева-Янковская</span>
                            <span className="team_director_text">Основатель и генеральный директор академии DenkArt, главный специалист по поступлению и переезду</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="team_container">
                <div className="team_items">
                    <TeamItem 
                        photo={worker1}
                        name="Дмитрий"
                        description="Специалист по внутренней оптимизации академии, специалист по поступлению и переезду в Австрию"
                    />
                    <TeamItem 
                        photo={worker2}
                        name="Денис"
                        description="Специалист по разработке и оптимизации сайта"
                    />
                    <TeamItem 
                        photo={worker3}
                        name="Жания"
                        description="Специалист по поступлению и переезду в Германию"
                    />
                    <TeamItem 
                        photo={worker11}
                        name="Софья"
                        description="Специалист по поступлению и переезду в Австрию"
                    />
                    <TeamItem 
                        photo={worker10}
                        name="Виктория"
                        description="Визуал-специалист, таргетолог"
                    />
                    <TeamItem 
                        photo={worker9}
                        name="Екатерина"
                        description="Маркетолог"
                    />
                    <TeamItem 
                        photo={worker5}
                        name="Александра"
                        description="Специалист-куратор"
                    />
                    <TeamItem 
                        photo={worker6}
                        name="Мадияр"
                        description="Специалист-куратор"
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Team