import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    FormControl,
    LinearProgress,
    Modal,
} from "@mui/material";
import "./style.scss";
import wheel from "../../img/fortuna/wheel.svg";
import arrow from "../../img/fortuna/arrow.svg";
import freeConsSendIcon from "../../img/index/otpravit-zayavku.svg";
import { closeModal, openFortuna } from '../../redux/slices/checkOddsSlice';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ru from "react-phone-input-2/lang/ru.json";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios from "axios";
import { openModal } from '../../redux/slices/checkOddsSlice';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

type FormConsultation = {
    first_name: string;
    email_address: string;
    phone_number: string;
    application_type: string;
    reward: string | undefined;
    country: string,
};

interface StepState {
    current: number;
    max: number;
    rewardId: number | undefined;
    rewardTitle: string | undefined;
    isSending: boolean;
}

const ModalFortune = () => {
    // Массив наград
    const rewards = [
        { id: 1, name: "Проверить шансы на поступление" }, // 0-45
        { id: 2, name: "Пройди игру - получи 30 евро" }, //45-90
        { id: 3, name: "Чек-лист «Топ-10 советов с чего начать»" }, // 90-135
        { id: 4, name: "Бесплатная консультция" }, //135-180
        { id: 5, name: "Тест на профориентацию" }, //180-225
        { id: 6, name: "Бесплатный урок по немецкому языку" }, //225-270
        { id: 7, name: "Чек-лист «7 ошибок студентов»" }, //270-315
        { id: 8, name: "Бесплатная консультация" }, //315-360
    ];

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormConsultation>();
    const dispatch = useAppDispatch();

    const { isOpenFortuna } = useAppSelector((state: RootState) => state.checkOdds);

    const [step, setStep] = useState<StepState>({
        current: 0,
        max: 3,
        rewardId: 0,
        rewardTitle: "",
        isSending: false,
    });

    const [phone, setPhone] = useState({
        country: "",
        number: ""
    });
    const [rotation, setRotation] = useState(0);
    const [isSpinningSlowly, setIsSpinningSlowly] = useState(true);

    const handleChangeCountry = (value: any, country: any) => {
        console.log(value);
        
        setPhone({
            number: value,
            country: country.name
        });
    };

    useEffect(() => {
        const hasUsedWheelFortune = localStorage.getItem('hasUsedWheelFortune');
        
        if (!hasUsedWheelFortune) {

            setTimeout(() => {
                dispatch(openFortuna({}));
            }, 7000); // 6 секунд задержки
            
        }
    }, [dispatch]);

    const onSubmit: SubmitHandler<FormConsultation> = (data) => {
        if (step.current > 0 && step.current != 2) {
            return;
        }
        localStorage.setItem('hasUsedWheelFortune', 'true');
        setIsSpinningSlowly(false);
        const newRotation = Math.floor(Math.random() * 3600) + 360; // Увеличим вращение для более динамичного эффекта
        setRotation((prevRotation) => prevRotation + newRotation); // Прибавляем новое вращение к предыдущему состоянию

        const finalAngle = (rotation + newRotation) % 360; // Угол после завершения вращения с учетом всех предыдущих вращений
        const sector = rewards.find((reward, index) => {
            const startAngle = index * 45; // Начальный угол сектора
            const endAngle = (index + 1) * 45; // Конечный угол сектора
            return finalAngle >= startAngle && finalAngle < endAngle;
        });

        setStep({
            ...step,
            current: 1,
            rewardId: sector?.id,
            rewardTitle: sector?.name,
        });

        handleSubmit(onSubmit);

        setTimeout(() => {
            setStep((prevStep) => ({
                ...prevStep,
                current: 2,
                isSending: false,
            }));
            
        }, 6000); // 6 секунд задержки

        data.application_type = "fortuna";
        data.reward = sector?.name;
        data.country = phone.country;
        data.phone_number = phone.number;

        const dataSend = new FormData();
        const config = {
            method: "post",
            url: "https://api2.denkart.academy/api/application",
            data: dataSend,
        };

        dataSend.append("data", JSON.stringify(data));

        axios(config)
            .then(function (response) {
                reset();
            })
            .catch(function (error) {
                alert(error);
            });
    };

   


    const getFinalStep = () => {
        switch (step.rewardId) {
            case 4:
            case 5:
            case 6:
            case 8:
                return (
                    <div className="modal-fortune_right_final">
                        <h3 className="modal-fortune_right_final_title">
                            Ура!<br/>Вам выпал приз "{step.rewardTitle}"!
                        </h3>
                        <span className="modal-fortune_right_final_text">
                            Мы получили Ваш номер телефона и свяжемся с Вами в течение рабочего дня!
                        </span>
                    </div>
                );
                //инфа что мы свяжемся с вами
                break;

            case 1:
                return (
                    <div className="modal-fortune_right_final">
                        <h3 className="modal-fortune_right_final_title">
                            Ура!<br/>Вы можете проверить свои шансы на поступление!
                        </h3>
                        <span className="modal-fortune_right_final_text">
                            Нажмите на кнопку ниже, чтобы открылся квиз.
                        </span>
                        <input type="button" value="Проверить шансы на поступление" className="modal-fortune_right_final_button" onClick={()=>dispatch(openModal({}))}/>
                    </div>
                );
                //закрыть колесо
                //открыть модальное окно с проверкой шансов
                break;

            case 2:
                return (
                    <div className="modal-fortune_right_final">
                        <h3 className="modal-fortune_right_final_title">
                            Ура!<br/>Пройти игру можно в нашем телеграм-боте!
                        </h3>
                        <a href="https://t.me/denkartbot" className="modal-fortune_right_final_button">Перейти в чат-бот</a>
                    </div>
                );
                break;

            case 3:
                return (
                    <div className="modal-fortune_right_final">
                        <h3 className="modal-fortune_right_final_title">
                            Ура!<br/>Вы можете скачать файл нажав на кнопку
                        </h3>
                        <span className="modal-fortune_right_final_text">
                            Данный чек-лист станет Вашим первооткрывателем в мир поступления.
                        </span>
                        <a href="https://denkart.academy/docs/чек-лист.pdf" download="Чек-лист.pdf" target="_blank" className="modal-fortune_right_final_button">Скачать PDF файл</a>
                    </div>
                );
                break;

            case 7:
                return (
                    <div className="modal-fortune_right_final">
                        <h3 className="modal-fortune_right_final_title">
                            Ура!<br/>Вы можете скачать файл нажав на кнопку
                        </h3>
                        <span className="modal-fortune_right_final_text">
                            Данный чек-лист предостережёт Вас от часто допускаемых ошибок.
                        </span>
                        <a href="https://denkart.academy/docs/частые-ошибки-студентов.pdf" download="Чек-лист.pdf" target="_blank" className="modal-fortune_right_final_button">Скачать PDF файл</a>
                    </div>
                );
                break;
        }
    };

    const getStep = () => {
        switch (step.current) {
            case 1:
                return (
                <>
                    <span className="modal-fortune_right_form_text" style={{textAlign: "center"}}>
                        Подождите пока колесо крутится. Вскоре Вы получите свой выигрыш
                    </span>
                    <CircularProgress color="info" />
                </>);

                //Подождите пока колесо крутится и Вы получите свой выигрыш
            case 2:
                return (
                    <>
                        {getFinalStep()}
                        {/* <div className="modal-fortune_right_form_buttons">
                            <button
                                className="modal-fortune_right_form_close"
                                onClick={() => onSubmit}>
                                <span>Закрыть</span>
                            </button>
                        </div> */}
                        <div className="modal-fortune_right_form_buttons">
                            <button
                                className="modal-fortune_right_form_submit"
                                onClick={() => handleSubmit(onSubmit)()}>
                                <span>Крутить ещё раз!</span>
                            </button>
                            <button
                                className="modal-fortune_right_form_close"
                                onClick={()=>dispatch(closeModal())}>
                                <span>Закрыть</span>
                            </button>
                        </div>
                    </>
                );
            default:
                return (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="modal-fortune_right_form"
                        style={
                            step.current === 0
                                ? { display: "flex" }
                                : { display: "none" }
                        }>
                        <h3 className="modal-fortune_right_form_title">
                            Крутите колесо!
                        </h3>
                        <span className="modal-fortune_right_form_text">
                            Введите свой номер телефона
                        </span>
                        <FormControl fullWidth size="small">
                            <Controller
                                name="phone_number"
                                control={control}
                                render={({ field }) => (
                                    <ReactPhoneInput
                                        {...register("phone_number", {
                                            required: true,
                                        })}
                                        {...field}
                                        value={field.value} // Set the default country value
                                        country="by"
                                        localization={ru}
                                        inputProps={{
                                            name: "contactPhone",
                                            required: true,
                                            autoformat: true,
                                        }}
                                        onChange={(value, country) => {
                                            field.onChange(value);
                                            handleChangeCountry(value, country);
                                        }} // Update the field value on change
                                    />
                                )}
                            />
                            {errors.phone_number && (
                                <span className="modal-fortune_right_form_error">
                                    Обязательное поле
                                </span>
                            )}
                        </FormControl>

                        <span className="modal-fortune_right_form_item_text">
                            *где есть Viber, WhatsApp, Telegram
                        </span>
                        <div className="modal-fortune_right_form_buttons">
                            <button
                                className="modal-fortune_right_form_submit"
                                onClick={() => onSubmit}>
                                <span>Крутить!</span>
                            </button>
                            <button
                                className="modal-fortune_right_form_close"
                                onClick={()=>dispatch(closeModal())}>
                                <span>Закрыть</span>
                            </button>
                        </div>
                    </form>
                );
        }
    };

    return (
        <div className={`modal-fortune ${isOpenFortuna ? "modal-fortune_open" : ""}`}>
            <div className="modal-fortune_container">
                <div className="modal-fortune_wrapper">
                    <div className="modal-fortune_left">
                        <img
                            src={arrow}
                            className="modal-fortune_arrow"
                            alt="Arrow"
                        />
                        <img
                            src={wheel}
                            style={{ transform: `rotate(${rotation}deg)` }}
                            className="modal-fortune_wheel"
                            alt="Wheel"
                        />
                    </div>
                    <div className="modal-fortune_right">{getStep()}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalFortune;
