import './style.scss';

interface ItemComplexType {
    title: string;
    value: string;
    color: string;
}

const ItemComplex = ({title, value, color}: ItemComplexType) => {
  return (
    <div className="offers-item-complex">
      <div className="offers-item-complex_top">
        <div className="offers-item-complex_icon" style={{background: color}}></div>
        <h1 className="offers-item-complex_title">{title}</h1>
      </div>
      {value && <span className="offers-item-complex_description">{value}</span>}
    </div>
  )
}

export default ItemComplex;