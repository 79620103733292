import { useState, useEffect } from "react";

interface Props {
  words: string[];
  id: string;
  colors?: string[];
}

const ConsoleText = ({ words, id, colors = ["#fff"] }: Props) => {
  const [visible, setVisible] = useState(true);
  const [letterCount, setLetterCount] = useState(1);
  const [x, setX] = useState(1);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (letterCount === 0 && !waiting) {
        setWaiting(true);
        const usedColor = colors.shift();
        colors.push(usedColor!);
        const usedWord = words.shift();
        words.push(usedWord!);
        setX(1);
        setLetterCount((prevCount) => prevCount + x);
        setWaiting(false);
      } else if (
        letterCount === words[0].length + 1 &&
        !waiting
      ) {
        setWaiting(true);
        setTimeout(() => {
          setX(-1);
          setLetterCount((prevCount) => prevCount + x);
          setWaiting(false);
        }, 1000);
      } else if (!waiting) {
        setLetterCount((prevCount) => prevCount + x);
      }
    }, 120);

    const underscoreInterval = setInterval(() => {
      setVisible((prevVisible) => !prevVisible);
    }, 400);

    return () => {
      clearInterval(interval);
      clearInterval(underscoreInterval);
    };
  }, [colors, letterCount, waiting, words, x]);

  return (
    <div>
      <span id={id}>{words[0].substring(0, letterCount)}</span>
      <span
        id="console"
        className={
          visible ? "console-underscore" : "console-underscore words_hidden"
        }
      >
        _
      </span>
    </div>
  );
};

export default ConsoleText;
