import { Helmet } from 'react-helmet';
import { JsonLd } from 'react-schemaorg';
import { Organization } from "schema-dts";

interface SeoType {
    titleTxt: string;
    description: string;
}

const Seo = ({titleTxt, description}:SeoType) => {
  return (
    <>
        <Helmet>
            <title>{titleTxt}</title>
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={titleTxt} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://denkart.academy/img/seo/main.jpg" />
            <meta property="og:url" content="https://denkart.academy" />
        </Helmet>
        <JsonLd<Organization>
            item={{
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'DenkArt - Академия поступления и переезда',
                description: 'Гарантированное поступление в ВУЗы Австрии и Германии по договору. Оформление документов, жилья и студенческой визы.',
                url: 'https://denkart.academy',
                logo: 'https://denkart.academy/img/seo/main.jpg',
                telephone: '+43 677 61208494',
                email: 'denkart.da@gmail.com',
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'Wehlistraße 330',
                    addressLocality: 'Вена ',
                    postalCode: '1020',
                    addressCountry: 'AT'
                }
            }}
        />
    </>
  )
}

export default Seo;