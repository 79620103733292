import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "./style.scss";
import "swiper/css/navigation";
import "swiper/css/pagination";

import discountImg from "../../../../img/index/banner-skidka-za-druga.png";
import checklistImg from "../../../../img/index/skachat-besplatnyj-cheklist.svg";
import checklistIcon from "../../../../img/index/glavnaya-chekist-kartinka.png";
import ModalChecklist from "../../../../components/ModalChecklist";
import { useAppDispatch } from "../../../../app/hooks";
import { openModalChecklist, openModalRecall } from "../../../../redux/slices/checkOddsSlice";

const Slider = () => {
    const dispatch = useAppDispatch();

    return (
        <>
            <Swiper
                className="banners_slider"
                modules={[Autoplay, Navigation, Pagination]}
                navigation={true}
                pagination={true}
                style={{ color: "white" }}
                loop={true}
                autoplay={{
                    delay: 5000
                }}
            >
                <SwiperSlide className="swiper-slide banners_alena">
                    <div className="banners_alena_right">
                        <div className="banners_alena_right_block">
                            <h1 className="banners_alena_right_title">
                                Поможем поступить в престижный университет
                                Австрии и Германии
                            </h1>
                            <ul className="banners_alena_right_list">
                                <li className="banners_alena_right_list_item">
                                    Гарантия поступления по договору
                                </li>
                                <li className="banners_alena_right_list_item">
                                    Поэтапная оплата
                                </li>
                                <li className="banners_alena_right_list_item">
                                    Сопровождение специалиста 24/7
                                </li>
                            </ul>
                            <div className="banners_alena_right_item">
                                <h2 className="banners_alena_right_text">
                                    Скажи “DA” изменениям и начни свой путь прямо
                                    сейчас!
                                </h2>
                                <input
                                    id="myBtn"
                                    type="button"
                                    value="Да!"
                                    className="banners_alena_right_btn-open"
                                    onClick={() => dispatch(openModalRecall())}
                                />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide banners_discount">
                    <div className="banners_discount_block">
                        <div className="banners_discount_block_info">
                            <h2 className="banners_discount_text">
                                Новая бонусная система!
                            </h2>
                            <h1 className="banners_discount_title">
                                Приведи друга
                                <br />
                                получи{" "}
                                <span className="banners_discount_title_text">
                                    скидку 50€
                                </span>
                            </h1>
                        </div>
                        <div className="banners_discount_people">
                            <img
                                src={discountImg}
                                alt="Новая бонусная система! Скидка 10% за друга."
                                className="banners_discount_people_img"
                            />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide banners_checklist">
                    <div className="banners_checklist_block">
                        <div className="banners_checklist_info">
                            <h1 className="banners_checklist_title">
                                Скачай чек-лист
                            </h1>
                            <h2 className="banners_checklist_name">
                                Топ-10 советов
                            </h2>
                            <span className="banners_checklist_text">
                                Как хорошо начать семестр в Австрии и Германии
                            </span>
                            <div
                                id="banners_checklist_download"
                                className="banners_checklist_download"
                                onClick={() => dispatch(openModalChecklist())}>
                                <img
                                    src={checklistImg}
                                    alt="Скачать чек-лист как хорошо начать семестр в Австрии и Германии"
                                    className="banners_checklist_download_icon"
                                />
                                Получить бесплатно
                            </div>
                        </div>
                        <div className="banners_checklist_foto">
                            <img
                                src={checklistIcon}
                                alt="Скачать чек-лист бесплатно онлайн"
                                className="banners_checklist_img"
                            />
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <ModalChecklist />
        </>
    );
};

export default Slider;
