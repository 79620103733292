
import Team from './Team';
import './style.scss';

import mainPic from '../../img/about/obrazovanie-o-nas-informaciya.png';
import secondPic from '../../img/about/o-nas-chto-my-delaem.png';
import Opinions from '../../components/Opinions';
import Seo from '../../components/Seo';

const About = () => {
  return (
    <>
        <Seo 
            titleTxt="Информация об академии поступления и переезда DenkArt. Отзывы."
            description="Хочешь получить высшее образование в университетах Австрии и Германии или целостно овладеть немецким языком - тогда тебе в DenkArt!"
        />
        <section className="about-main">
            <div className="about-main_container">
                <div className="about-main_wrapper">
                    <div className="about-main_top">
                    <div className="page">
                        <div className="page_container">
                            <div className="page_wrapper">
                                <h1 className="page_title" style={{textAlign: 'left'}}>
                                    Мы <span className="page_title_orange">поможем</span> тебе здесь, там и тут
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="about-main_top_photo">
                        <img src={mainPic} alt="Образовательный центр DenkArt. О нас." className="about-main_top_photo_img" />
                    </div>
                    </div>
                    <div className="about-main_bottom">
                    <h2 className="about-main_bottom_title">
                        Академия поступления и переезда DenkArt – это твой <span className="about-main_bottom_color">проводник</span> в мир новых
                        возможностей!
                    </h2>
                    <p className="about-main_bottom_text">
                        Мы считаем, что каждый человек имеет право на мечту и на воплощение ее в реальность. Именно поэтому наша
                        команда
                        стремится помочь каждому, кто решил выбрать для себя непростой путь переезда за границу или изучения нового
                        языка. Своими силами и накопленными знаниями мы помогаем справляться с трудностями, которые встречаются на
                        неизведанном пути.
                    </p>
                    <p className="about-main_bottom_text">
                        Для того чтобы начать новую главу в жизни с радости и побед нужно всего лишь обратиться в <span
                        className="about-main_bottom_color">DenkArt</span>!
                    </p>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="whatme">
            <div className="whatme_container">
                <div className="whatme_wrapper">
                    <div className="whatme_top">
                    <div className="whatme_top_left">
                        <h1 className="whatme_top_title">Что мы делаем?</h1>
                        <p className="whatme_top_text">Если ты хочешь получить высшее образование в <span
                            className="about-main_bottom_color">университетах <a href="/offers">Австрии</a> и <a href="/offers-germany">Германии</a></span> или целостно овладеть <a href="/school">немецким языком</a> тогда тебе в <span className="about-main_bottom_color">DenkArt</span>!</p>
                    </div>
                    <div className="whatme_top_right">
                        <div className="whatme_top_right_photo">
                        <img src={secondPic} alt="Что мы делаем. DenkArt" className="whatme_top_right_photo_img" />
                        </div>
                    </div>
                    </div>
                    <div className="whatme_item">
                    <h1 className="whatme_item_title"><span className="whatme_item_orange">От идеи</span> обучения за
                        границей&nbsp;-<br/><span className="whatme_item_pink">До получения</span> студенческого вида на жительство</h1>
                    <p className="whatme_item_text">
                        DenkArt помогает тебе подготовиться и поступить в лучшие университеты Австрии и Германии, а также освоиться
                        и стать полноценным гражданином Европы. Мы создаем максимально благоприятные условия для студентов на всех
                        этапах совместной работы. Отталкиваясь от индивидуальных потребностей каждого, помогаем подобрать правильный
                        университет и определиться с программой обучения. В течение всей совместной работы остаемся на связи 24/7 и
                        оказываем моральную поддержку.
                    </p>
                    </div>
                    <div className="whatme_item">
                    <h1 className="whatme_item_title"><span className="whatme_item_orange">От мечты</span> свободно говорить на
                    иностранном языке&nbsp;-<br/><span className="whatme_item_pink">До владения языком</span> на уровне носителя</h1>
                    <p className="whatme_item_text">
                        DenkArt предоставляет профессиональные <a href="/school">курсы немецкого языка</a> от уровня А1 до С1, также готовит студентов к
                        сдаче
                        экзаменов Goethe, ÖSD. Собственно разработанная методика изучения языка облегчает восприятие материала и
                        делает процесс интересным. Благодаря лучшим специалистам и индивидуальному подходу студент максимально
                        быстро погружается в работу, начиная сразу показывать хорошие результаты.
                    </p>
                    </div>
                </div>
            </div>
        </section>
        
        <Team />
    </>
  )
}

export default About