import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle';
import moment from 'moment';
import 'moment/locale/ru';

import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Seo from '../../components/Seo';

const News = () => {
  moment.locale('ru');
  AOS.init();

  const [data, setData] = useState([]);

    useEffect(() => {
    fetch('https://api2.denkart.academy/api/get_news')
        .then(response => response.json())
        .then(data => setData(data));
    }, []);
  return (
    <>
        <Seo 
          titleTxt="Новости про университеты и высшее образование в Австрии и Германии. DenkArt."
          description="Актуальные новости об образовании в Австрии и Германии."
        />
        <PageTitle 
            firstTitle='Актуальное'
            secondTitle='для&nbsp;студентов'
            textAlign='center'
        />
        <div className="news">
            <div className="news_container">
                <div className="news_wrapper"> 
                {data.map((item, index) => (
                  <div className="news_item news_item_two"
                    key={index}
                    data-aos="zoom-in"
                  >
                        <div 
                          className="news_item_background" 
                          style={{background: `#D9D9D9 url(${item[4]})`, backgroundSize: "cover", backgroundPosition: "center"}}
                        ></div>
                        <h3 className="news_item_title">{item[2]}</h3>
                        <p className="news_item_text">{item[3]}</p>
                        <span className="news_item_date">{moment(item[5], 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY в HH:mm')}</span>
                  </div>
                ))}
                    
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default News;