import {  useRef, useState } from "react";
import './style.scss';

interface DetailsType {
    summary: string;
    text: string;
}

const Details = ({summary, text}:DetailsType) => {

    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);
    const height = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        if (height.current !== null) {
            setIsOpen(!isOpen);
            const heightElem = height.current.offsetHeight;
            setMaxHeight(heightElem);
        }
    }

    return (
    <div className={`details ${isOpen ? 'open' : ''}`} onClick={() => handleOpen()} >
        <div className="details_summary">{summary}</div>
        <div className={`details_content ${isOpen ? 'open' : ''}`} style={isOpen ? {maxHeight: maxHeight} : {}}>
            <p className="details_content_text" ref={height} dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
    </div>
    )
}

export default Details;