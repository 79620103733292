import "../OffersAustria/style.scss";
import ButtonInformation from "../../components/Offers/ButtonInformation";
import ItemSimple from "../../components/Offers/ItemSimple";
import ItemComplex from "../../components/Offers/ItemComplex";
import PageTitle from "../../components/PageTitle";
import ComplexItem from "../../components/Offers/ComplexItem";

import data from "../../data/offers-germany.json";
import { useState } from "react";
import Seo from "../../components/Seo";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Modal, Box, TextField, Button, LinearProgress } from "@mui/material";
import React from "react";
import { closeModal } from "../../redux/slices/newOrderSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios from "axios";
import FreeConsultation from "../Index/Banners/FreeConsultation";

type FormOrder = {
    application_type: string;
    product: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email_address: string;
    comment: string;
};

const OffersGermany = () => {
    // eslint-disable-next-line
    const [complex, setComplex] = useState(data);

    const [isSending, setIsSending] = useState(false);
    const [isOpenResultDialog, setIsOpenResultDialog] = useState(false);
    const [modalInfo, setModalInfo] = useState("");

    const { selectedComplex, country, isOpenModal } = useAppSelector(
        (state: RootState) => state.newOrder
    );
    const dispatch = useAppDispatch();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormOrder>();

    const onSubmit: SubmitHandler<FormOrder> = (data) => {
        setIsSending(true);

        data.application_type = "new_order";
        data.product = `${selectedComplex} (${country})`;

        const dataSend = new FormData();
        const config = {
            method: "post",
            url: "https://api2.denkart.academy/api/application",
            data: dataSend,
        };

        dataSend.append("data", JSON.stringify(data));

        axios(config)
            .then(function (response) {
                setIsSending(false);
                setIsOpenResultDialog(true);
                setModalInfo(response.data.message_user);
                reset();
            })
            .catch(function (error) {
                setIsSending(false);
                setIsOpenResultDialog(true);
                console.log(error);

                setModalInfo(error.response.data.message_user);
            });
    };

    const style = {
        input: {
            background: "#fff",
            borderRadius: "10px",
            marginBottom: "20px",
        },
        modal: {
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            outline: "none",
        },
        button: {
            background: "#FFB078",
            color: "#000",
            "&:hover": {
                bgcolor: "#e9a16f", // change the background color on hover
            },
        },
        cancel: {
            border: "1px solid #000",
            color: "#000",
            marginRight: "15px",
        },
    };

    const handleClose = () => {
        reset();
        dispatch(closeModal());
        setIsOpenResultDialog(false);
    };

    return (
        <>
            <Seo
                titleTxt="Образование в Австрии. Поступить в ВУЗ Австрии. DenkArt."
                description="Образование и переезд в Австрию. Бесплатная консультация. Подготовка к поступлению и получению студенческой визы. Полное сопровождение."
            />
            <PageTitle
                firstTitle="Услуги"
                secondTitle="по&nbsp;Германии"
                textAlign="center"
            />
            <section className="main">
                <div className="main_container">
                    <div className="main_wrapper">
                        <div className="main_item">
                            <h1 className="main_item_title">
                                Комплексные услуги по поступлению в университеты
                                Германии
                            </h1>
                            <span className="main_item_text">
                                Полное сопровождение начиная от выбора
                                университета и заканчивая получением
                                студенческого вида на жительство.
                            </span>
                            <div className="main_divs">
                                <ItemComplex
                                    title="Classic"
                                    value="идеально подходит для тех, кто нуждается лишь в наставнике, который знает ответ на любой вопрос и поможет с их решением"
                                    color="#394263"
                                />
                                <ItemComplex
                                    title="Silver"
                                    value="вы сможете пользоваться онлайн поддержкой ментора в течение всей совместной работы"
                                    color="#865C74"
                                />
                                <ItemComplex
                                    title="Gold"
                                    value="вы получаете личное сопровождение ментора на всех стадиях поступления, оформления документов, переезда и адаптации"
                                    color="#FFB078"
                                />
                            </div>
                            <ButtonInformation link="docs/комплексные-услуги-Германия.pdf" />
                        </div>
                        <div className="main_item">
                            <h1 className="main_item_title">
                                Подготовка к поступлению в университеты Германии
                            </h1>
                            <div className="main_divs">
                                <ItemSimple value="Составление мотивационного письма" />
                                <ItemSimple value="Составление автобиографии" />
                                <ItemSimple value="Подбор университетов и программ обучения" />
                                <ItemSimple value="Проверка полного пакета документов перед подачей в университет" />
                            </div>
                            <span className="main_item_text">
                                Подробную информацию можно скачать в файле ниже
                            </span>
                            <ButtonInformation link="docs/подготовка-к-поступлению-Германия.pdf" />
                        </div>
                        <div className="main_item">
                            <h1 className="main_item_title">
                                Дополнительные услуги
                            </h1>
                            <div className="main_divs">
                                <ItemSimple value="Онлайн консультация" />
                                <ItemSimple value="Помощь при оформлении вида на жительство / визы" />
                                <ItemSimple value="Помощь при продлении вида на жительство" />
                                <ItemSimple value="Помощь при поиске жилья" />
                                <ItemSimple value="Помощь в адаптации по приезде" />
                            </div>
                            <span className="main_item_text">
                                Подробную информацию можно скачать в файле ниже
                            </span>
                            <ButtonInformation link="docs/дополнительные-услуги-Германия.pdf" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="complex">
                <div className="complex_container">
                    <h1 className="complex_title">Виды комплексных услуг</h1>
                    <div className="complex_wrapper">
                        <ComplexItem
                            title="Classic"
                            color="#394263"
                            oldPrice="1400"
                            currentPrice="1200"
                            country="Германия">
                            {complex.classic.map((item) => {
                                return (
                                    <ItemComplex
                                        title={item}
                                        value=""
                                        color="#394263"
                                    />
                                );
                            })}
                        </ComplexItem>
                        <ComplexItem
                            title="Silver"
                            color="#865C74"
                            oldPrice="2700"
                            currentPrice="2400"
                            country="Германия">
                            <ItemComplex
                                title="Всё, что в пакете Classic"
                                value=""
                                color="#394263"
                            />
                            {complex.silver.map((item) => {
                                return (
                                    <ItemComplex
                                        title={item}
                                        value=""
                                        color="#865C74"
                                    />
                                );
                            })}
                        </ComplexItem>
                        <ComplexItem
                            title="Gold"
                            color="#FFB078"
                            oldPrice="3550"
                            currentPrice="3000"
                            country="Германия">
                            <ItemComplex
                                title="Всё, что в пакете Classic и Silver"
                                value=""
                                color="#865C74"
                            />
                            {complex.gold.map((item) => {
                                return (
                                    <ItemComplex
                                        title={item}
                                        value=""
                                        color="#FFB078"
                                    />
                                );
                            })}
                        </ComplexItem>
                    </div>
                </div>
            </div>
            {/* <FreeConsultation /> */}
            <Modal open={isOpenModal} onClose={handleClose}>
                <Box className="modal-order">
                    <div
                        className="modal-order_wrapper"
                        style={isOpenResultDialog ? { display: "none" } : {}}>
                        <h1 className="modal-order_title">Оформить заказ</h1>
                        <p className="modal-order_item">
                            Вы выбрали <b>пакет {selectedComplex}</b>. Оставьте
                            пожалуйста свои контактные данные и наш менеджер
                            свяжется с Вами
                        </p>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="modal-order_form">
                            <div className="mmodal-order_form_contact">
                                <input
                                    type="hidden"
                                    name="product"
                                    id="product"
                                    value="Пакет NULL"
                                />
                                <TextField
                                    size="small"
                                    label="Ваше имя"
                                    fullWidth
                                    {...register("first_name", {
                                        required: true,
                                    })}
                                    error={!!errors.first_name}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                                <TextField
                                    size="small"
                                    label="Ваша фамилия"
                                    fullWidth
                                    {...register("last_name", {
                                        required: true,
                                    })}
                                    error={!!errors.last_name}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                                <TextField
                                    size="small"
                                    label="Контактный номер телефона"
                                    fullWidth
                                    {...register("phone_number", {
                                        required: true,
                                    })}
                                    error={!!errors.phone_number}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                                <Controller
                                    name="email_address"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Invalid email format",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            size="small"
                                            fullWidth
                                            label="Email"
                                            variant="outlined"
                                            {...register("email_address", {
                                                required: true,
                                            })}
                                            error={!!errors.email_address}
                                            InputProps={{
                                                style: style.input,
                                            }}
                                        />
                                    )}
                                />
                                {/* <TextField 
                    size='small'
                    label="Адрес электронной почты"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    {...register("email_address", { required: true })}
                    error={!emailError}
                    InputProps={{
                        style: style.input,
                    }}
 
                /> */}
                                <TextField
                                    size="small"
                                    label="Комментарий (необязательно)"
                                    fullWidth
                                    {...register("comment")}
                                    InputProps={{
                                        style: style.input,
                                    }}
                                />
                            </div>

                            <div className="modal-order_btns">
                                <Button
                                    sx={style.cancel}
                                    type="submit"
                                    variant="outlined"
                                    onClick={() => handleClose()}>
                                    Отменить
                                </Button>
                                <Button
                                    sx={style.button}
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}>
                                    Отправить
                                </Button>
                                {isSending && (
                                    <LinearProgress
                                        color="secondary"
                                        style={{ marginTop: "10px" }}
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                    <div
                        className="modal-order_wrapper"
                        style={
                            isOpenResultDialog
                                ? { display: "flex" }
                                : { display: "none" }
                        }>
                        <h1 className="modal-order_title">
                            Спасибо! Заявка отправлена!
                        </h1>
                        <p className="modal-order_item" id="final-text">
                            {modalInfo}
                        </p>
                        <Button
                            sx={style.button}
                            type="submit"
                            variant="contained"
                            onClick={() => handleClose()}>
                            Спасибо, жду
                        </Button>
                    </div>
                </Box>
            </Modal>
            <FreeConsultation isGermany="1" />
        </>
    );
};

export default OffersGermany;
