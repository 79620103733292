import React, { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import opinionLogo from '../../img/about/opinia/opinion_logo.svg';
import starGood from '../../img/about/opinia/good.svg';
import starBad from '../../img/about/opinia/bad.svg';
import './style.scss';

const Opinions = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
    fetch('https://api2.denkart.academy/api/get_opinions')
        .then(response => response.json())
        .then(data => setData(data));
    }, []);

    return (
        <>
        <section className="opinion">
            <h1 className="opinion_title">Отзывы наших студентов</h1>
            <Swiper
                className='opinion_container'
                modules={[Autoplay, Navigation, Pagination]}
                navigation={true}
                pagination={true}
                loop={true}
                autoplay={{
                    delay: 5000
                }}
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={{
                    665: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    900: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    }
                }}
            >
                {data.map((item, index) => (
                    <SwiperSlide className="opinion_slide swiper-slide" key={index}>
                        <div className="opinion_slide_wrap">
                            <img src={opinionLogo} alt="Отзывы наших студентов" className="opinion_slide_icon" />
                            <span className="opinion_slide_text">{item[2]}</span>
                            <div className="opinion_slide_info">
                                <div className="opinion_slide_points">  
                                    {[...Array(Number(item[1]))].map((star, index) => <img key={index} src={starGood} alt="*" className="opinion_slide_points_item_good"/>)}
                                    {[...Array(Number(5-item[1]))].map((star, index) => <img key={index} src={starBad} alt="*" className="opinion_slide_points_item_good"/>)}
                                </div>
                                <span className="opinion_slide_info_name">{item[0]}</span>
                                <span className="opinion_slide_info_date">{item[3]}</span>
                            </div>
                        </div>
                    </SwiperSlide> 
                ))}
            </Swiper>
        </section>
        </>
        );
        
}

export default Opinions