import './style.scss';

import { useAppDispatch } from '../../app/hooks';
import { openModalRecall } from '../../redux/slices/checkOddsSlice';

const Recall = () => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="mymagicoverbox"onClick={()=>dispatch(openModalRecall())}>
                <div className="circlephone"></div>
                <div className="circle-fill"></div>
                <div className="img-circle">
                    <div className="img-circleblock"></div>
                </div>
            </div>
        </>
    );
};

export default Recall;
