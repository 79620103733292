
import './style.scss';
import logoHeader from '../../img/header/logo_header.png';
import iconInsta from '../../img/header/instagram-ikonka.svg';
import iconVk from '../../img/header/vk-ikonka.svg';
import iconTelegram from '../../img/header/telegram-ikonka.svg';
import iconFb from '../../img/header/fejsbuk-ikonka.svg';
import youtubeIcon from '../../img/social/youtube.svg';
import iconLangRu from '../../img/lang/RU.png';
import iconLangEn from '../../img/lang/EN.png';
import iconLangDe from '../../img/lang/DE.png';
import { useEffect, useState } from 'react';
import Localized from '../../Localized';

import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { openModal, openFortuna } from '../../redux/slices/checkOddsSlice';
import DropdownButton from './DropDown';
import iconFortuna from '../../img/fortuna/icon.svg';

const Header = () => {

    const [isOpenMobile, setOpenMobile] = useState(false);

    const dispatch = useAppDispatch();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);

    return (
        <>
            <div className="header_common">
                <div className="check-odds_banner">
                    <div className="check-odds_banner_container">
                        <div className="check-odds_banner_wrapper">
                            <input id="myBtn" type="button" value="Проверить шансы на поступление" className="check-odds_banner_btn-open" onClick={()=>dispatch(openModal({}))}/>
                            <button id="myBtn" type="button" value="Проверить шансы на поступление" className="check-odds_banner_btn-open" onClick={()=>dispatch(openFortuna({}))}>
                                Крутить <img src={iconFortuna} alt="" style={{width: "30px"}}/> 
                            </button>
                        </div>
                    </div>
                </div>
                <header className="header">
                    <div className="header_container">
                        <div className="header_wrapper">
                            <Link to="/" className="header_logo">
                                <img src={logoHeader} alt="DenkArt" className="header_logo_img" />
                            </Link>
                            <ul className="header_menu">
                                <li><Link to="/about" className="header_menu_item">{Localized.HeaderBtnAbout}</Link></li>
                                <li><span className="header_menu_item">{Localized.HeaderBtnEducation}</span>
                                    <ul className="submenu">
                                        <li><Link to="/information">{Localized.HeaderBtnAustria}</Link></li>
                                        <li><Link to="/information-germany">{Localized.HeaderBtnGermany}</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/school" className="header_menu_item">{Localized.HeaderBtnSchool}</Link></li>
                                <li><span className="header_menu_item">{Localized.HeaderBtnOffers}</span>
                                    <ul className="submenu">
                                        <li><Link to="/offers">{Localized.HeaderBtnAustria}</Link></li>
                                        <li><Link to="/offers-germany">{Localized.HeaderBtnGermany}</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/news" className="header_menu_item">{Localized.HeaderBtnNews}</Link></li>
                            </ul>
                            <div className="header_social">
                                <a href="https://instagram.com/denkart_academy" target="_blank" rel="noreferrer" className="header_social_item">
                                    <img src={iconInsta} alt="Инстаграм DenkArt" className="header_social_item_img" />
                                </a>
                                <a href="https://vk.com/public210644128" target="_blank" rel="noreferrer" className="header_social_item">
                                    <img src={iconVk} alt="VK DenkArt" className="header_social_item_img" />
                                </a>
                                <a href="https://t.me/denkart_da" target="_blank" rel="noreferrer" className="header_social_item">
                                    <img src={iconTelegram} alt="Telegram DenkArt" className="header_social_item_img" />
                                </a>
                                <a href="https://youtube.com/channel/UCXVUavN9-TgMPsK8ya6twew" target="_blank" className="header_social_item">
                                    <img src={youtubeIcon} alt="Youtube DenkArt" className="header_social_item_img" />
                                </a>
                            </div>
                            <div className="header_mobile_open" onClick={() => setOpenMobile(true)}></div>
                        </div>
                    </div>
                </header>
            </div> 
            <section className={isOpenMobile ? "header_mobile_back header_mobile_back_active" : "header_mobile_back"} onClick={() => setOpenMobile(false)}></section>
            <div className={isOpenMobile ? "header_mobile header_mobile_active" : "header_mobile"}>
                <div className="header_mobile_close" onClick={() => setOpenMobile(false)}></div>
                <div className="sidenav">
                    <a href="about">О нас</a>
                    <DropdownButton 
                        title="Образование за рубежом"
                        services={
                            [
                                { name: "Австрия", link: "information" },
                                { name: "Германия", link: "information-germany" },
                            ]
                        }
                    />
                    <a href="school">Языковая школа</a>
                    <DropdownButton 
                        title="Услуги"
                        services={
                            [
                                { name: "Австрия", link: "offers" },
                                { name: "Германия", link: "offers-germany" },
                            ]
                        }
                    />
                    <a href="news">Новости</a>
                </div>
                <div className="header_mobile_social">
                    <a href="https://instagram.com/denkart_academy" rel="noreferrer" className="header_mobile_social_item" target="_blank">
                        <img src={iconInsta} alt="Instagram DenkArt" className="header_mobile_social_item_img" />
                    </a>
                    <a href="https://vk.com/public210644128" rel="noreferrer" className="header_mobile_social_item" target="_blank">
                        <img src={iconVk} alt="VK DenkArt" className="header_mobile_social_item_img" />
                    </a>
                    <a href="https://t.me/denkart_da" rel="noreferrer" className="header_mobile_social_item" target="_blank">
                        <img src={iconTelegram} alt="Telegram DenkArt" className="header_mobile_social_item_img" />
                    </a>
                    <a href="https://youtube.com/channel/UCXVUavN9-TgMPsK8ya6twew" target="_blank" className="header_mobile_social_item">
                        <img src={youtubeIcon} alt="Youtube DenkArt" className="header_mobile_social_item_img" />
                    </a>
                </div>
                <div className="header_mobile_contacts">
                    <div className="header_mobile_contacts_link">
                        <span className="header_mobile_contacts_link_text">Телефоны:</span>
                        <a href="tel:+4367761208494" rel="nofollow" className="header_mobile_contacts_link_a">+43 677 61208494</a>
                        <a href="tel:+491786812028" rel="nofollow" className="header_mobile_contacts_link_a">+49 178 6812028</a>
                    </div>
                    <div className="header_mobile_contacts_link">
                        <span className="header_mobile_contacts_link_text">Почта:</span>
                        <a href="mailto:info@denkart.academy" rel="nofollow" className="header_mobile_contacts_link_a">info@denkart.academy</a>
                    </div>
                </div>
            </div>
            <div className="header_dop"></div>
   
        </>
    )
}

export default Header;