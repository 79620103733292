import { Box, LinearProgress, Modal, TextField } from '@mui/material';
import  { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { closeModal } from '../../redux/slices/checkOddsSlice';
import freeConsSendIcon from '../../img/index/otpravit-zayavku.svg';

import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

import '../ModalOdds/quiz.scss';

type FormConsultation = {
    first_name: string,
    email_address: string,
    phone_number: string,
    application_type: string,
};

const ModalChecklist = () => {
    
    const { isOpenModalChecklist } = useAppSelector((state: RootState) => state.checkOdds);
    const [step, setStep] = useState({
        current: 0,
        max: 1,
        isSending: false,
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormConsultation>();
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<FormConsultation> = (data) =>  {
        setStep({
            ...step,
            isSending: true,
        });

        data.application_type = "checklist";

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://api2.denkart.academy/api/application',
            data : dataSend
        };

        dataSend.append('data', JSON.stringify(data));

        axios(config)
        .then(function (response) {
            setStep({
                ...step,
                current: 1,
                isSending: false,
            });
            reset();
        })
        .catch(function (error) {
            alert(error);
        });
    }

    return (
        <Modal
            open={isOpenModalChecklist}
            onClose={() => dispatch(closeModal())}
        >
            <Box className="check-odds">
                <div className="check-odds_container">
                    <div className="check-odds_content">
                        <div className="check-odds_header">
                            <div className="check-odds_close" onClick={()=>dispatch(closeModal())}></div>
                            <h2>Скачать чек-лист</h2>
                        </div>
                        <div className="check-odds_body">
                            <form onSubmit={handleSubmit(onSubmit)} className="contact-form" style={step.current == 0 ? {display: "flex"}: {display: "none"}}>
                                <TextField 
                                    size='small'
                                    label="Ваше имя"
                                    fullWidth
                                    {...register("first_name", { required: true })}
                                    error={!!errors.first_name}
                                    InputProps={{
                                        style: {
                                            background: "#fff",
                                            borderRadius: '10px', // Set the border radius value here
                                            marginBottom: "8px"
                                        },
                                    }}
                                />
                                <TextField 
                                    size='small'
                                    label="Email адрес"
                                    fullWidth
                                    {...register("email_address", { required: true })}
                                    error={!!errors.email_address}
                                    InputProps={{
                                        style: {
                                            background: "#fff",
                                            borderRadius: '10px', // Set the border radius value here
                                            marginBottom: "8px"
                                        },
                                    }}
                                />
                                <TextField 
                                    size='small'
                                    label="Номер телефона"
                                    fullWidth
                                    {...register("phone_number", { required: true })}
                                    error={!!errors.phone_number}
                                    InputProps={{
                                        style: {
                                            background: "#fff",
                                            borderRadius: '10px', // Set the border radius value here

                                        }
                                    }}
                                />
                                <span className="check-odds_contact_item_text">*где есть Viber, WhatsApp, Telegram</span>
                                <button className="contact-form_submit" onClick={() => onSubmit}>
                                    <img src={freeConsSendIcon} alt="Образовательный центр бесплатное первое занятие" className="contact-form_submit_icon" />
                                    <span>Получить чек-лист</span>
                                </button>
                                
                            </form>
                            { step.isSending && <LinearProgress 
                            color="secondary"
                            style={{marginTop: "10px", width: "200px"}}
                            /> }
                        </div>
                        <div className="check-odds_final" style={step.current == 1 ? {display: "flex"}: {display: "none"}}>
                            <span className="check-odds_final_text">Спасибо!<br/>Мы отправили чек-лист на почту, указанную в форме.</span>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalChecklist;