import freeLesson from '../../../../img/index/student-besplatnoe-zanyatie.png';
import freeLessonGet from '../../../../img/index/besplatnoe-zanyatie-nemeckogo.svg';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

import freeConsSendIcon from '../../../../img/index/otpravit-zayavku.svg';
import { useState } from 'react';

import { useForm, SubmitHandler } from "react-hook-form";

import { TextField } from '@mui/material';
import axios from "axios";


type FormFreeLesson = {
    first_name: string,
    email_address: string,
    phone_number: string,
    application_type: string,
};

const Lang = () => {

    const [ isOpenWindow, setIsOpen ] = useState(false);
    const [ isSending, setIsSending ] = useState(false);
    const [ isOpenResultDialog, setIsOpenResultDialog ] = useState(false);
    const [ modalInfo, setModalInfo ] = useState("");

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormFreeLesson>();

    const handleCloseResultDialog = () => {
        setIsOpenResultDialog(false);
    }

    const onSubmit: SubmitHandler<FormFreeLesson> = (data) =>  {

        setIsSending(true);

        data.application_type = "free_lesson";

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://api2.denkart.academy/api/application',
            data : dataSend
        };

        dataSend.append('data', JSON.stringify(data));

        axios(config)
        .then(function (response) {
            setIsSending(false);
            setIsOpenResultDialog(true);
            setModalInfo(response.data.message_user);
            reset();
        })
        .catch(function (error) {
            setIsSending(false);
            setIsOpenResultDialog(true);
            console.log(error);
            
            setModalInfo(error.response.data.message_user);
        });
    }

    return (
        <>
        <div className="banners_item banners_lang">
            <div className="banners_lang_block" style={!isOpenWindow ? {transform: "rotateY(0deg)"} : {transform: "rotateY(180deg)"}}>
                <h1 className="banners_lang_title">Немецкий<br/>за 2 месяца?</h1>
                <span className="banners_lang_warning">Реально!</span>
                <div className="banners_lang_free-lesson" onClick={() => setIsOpen(true)}>
                    <img src={freeLessonGet} alt="Получить бесплатное занятие по немецкому" className="banners_lang_free-lesson_icon" />
                    Получить<br/>бесплатное занятие
                </div>
                <img src={freeLesson} alt="Бесплатное занятие" className="banners_lang_student" />
            </div>
            <div className="banners_lang_back" style={!isOpenWindow ? {transform: "rotateY(180deg)"} : {transform: "rotateY(360deg)"}}>
                <h1 className="banners_lang_back_title">Бесплатное<br/>первое занятие</h1>
                <h2 className="banners_lang_back_text">Заполните форму и мы свяжемся с Вами<br/>в течение одного рабочего дня</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="banners_lang_back_form contact-form">
                    <TextField 
                        size='small'
                        placeholder="Ваше имя"
                        fullWidth
                        {...register("first_name", { required: true })}
                        error={!!errors.first_name}
                        InputProps={{
                            style: {
                                background: "#fff",
                                borderRadius: '10px', // Set the border radius value here
                                marginBottom: "8px"
                            },
                        }}
                    />
                    <TextField 
                        size='small'
                        placeholder="Email адрес"
                        fullWidth
                        {...register("email_address", { required: true })}
                        error={!!errors.email_address}
                        InputProps={{
                            style: {
                                background: "#fff",
                                borderRadius: '10px', // Set the border radius value here
                                marginBottom: "8px"
                            },
                        }}
                    />
                    <TextField 
                        size='small'
                        placeholder="Номер телефона"
                        fullWidth
                        {...register("phone_number", { required: true })}
                        error={!!errors.phone_number}
                        InputProps={{
                            style: {
                                background: "#fff",
                                borderRadius: '10px', // Set the border radius value here
                                marginBottom: "8px"
                            }
                        }}
                    />
                    <button className="contact-form_submit" onClick={() => onSubmit}>
                        <img src={freeConsSendIcon} alt="Образовательный центр бесплатное первое занятие" className="contact-form_submit_icon" />
                        <div className="loader"></div>
                        <span>Отправить заявку</span>
                    </button>
                    
                </form>
                <div className="banners_lang_back_close" onClick={() => setIsOpen(false)}></div>
            </div>
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSending}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
            open={isOpenResultDialog}
            onClose={handleCloseResultDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {modalInfo}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseResultDialog} autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default Lang