import { createSlice } from '@reduxjs/toolkit';

export interface CheckOddsType {
  answers: string[];
  isOpenFortuna: boolean;
  isOpenModal: boolean;
  isOpenModalChecklist: boolean;
  isOpenModalRecall: boolean;
}

const initialState: CheckOddsType = {
  answers: [],
  isOpenFortuna: false,
  isOpenModal: false,
  isOpenModalChecklist: false,
  isOpenModalRecall: false,
};

const checkOddsSlice = createSlice({
  name: 'checkodds',
  initialState,
  reducers: {
    openModal(state, action) {
      state.isOpenModal = true;
    },
    openFortuna(state, action) {
      state.isOpenFortuna = true;
      document.body.style.overflow = 'hidden';
    },
    openModalChecklist(state) {
      state.isOpenModalChecklist = true;
    },
    openModalRecall(state) {
      state.isOpenModalRecall = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
      state.isOpenModalChecklist = false;
      state.isOpenModalRecall = false;
      state.isOpenFortuna = false;
      document.body.style.overflow = '';
    },
    nextStep(state, {payload}) {
      state.answers[payload['ques']] = payload['answer'];
    }
  },
});

export const {
  openModal,
  closeModal,
  nextStep,
  openModalChecklist,
  openModalRecall,
  openFortuna
} = checkOddsSlice.actions;

export default checkOddsSlice.reducer;