import React from 'react';

import instIcon from '../../../../img/index/instagram-ikonka.svg';
import vkIcon from '../../../../img/index/vk-ikonka.svg';
import tgIcon from '../../../../img/index/telegram-ikonka.svg';
import fbIcon from '../../../../img/index/fejsbuk-ikonka.svg';
import youtubeIcon from '../../../../img/social/youtube.svg';

const Social = () => {
  return (
    <div className="banners_item banners_social">
        <div className="banners_social_block">
            <div className="banners_social_left">
                <h1 className="banners_social_left_title">Не забудьте подписаться на нас в социальных сетях</h1>
                <span className="banners_social_left_text">Там самая актуальная информация для самостоятельного поступления!</span>
            </div>
            <div className="banners_social_right">
                <a href="https://instagram.com/denkart_academy" target="_blank" className="banners_social_right_btn">
                    <img src={instIcon} alt="Instagram DenkArt" className="banners_social_right_btn_icon" />
                </a>
                <a href="https://vk.com/public210644128" target="_blank" className="banners_social_right_btn">
                    <img src={vkIcon} alt="Вконтакте DenkArt" className="banners_social_right_btn_icon" />
                </a>
                <a href="https://t.me/denkart_da" target="_blank" className="banners_social_right_btn">
                    <img src={tgIcon} alt="Telegram DenkArt" className="banners_social_right_btn_icon" />
                </a>
                <a href="https://youtube.com/channel/UCXVUavN9-TgMPsK8ya6twew" target="_blank" className="banners_social_right_btn">
                    <img src={youtubeIcon} alt="Youtube DenkArt" className="banners_social_right_btn_icon" />
                </a>
            </div>
        </div>
    </div>
  )
}

export default Social