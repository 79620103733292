import React from 'react';

import sendIcon from '../../../../img/index/otpravit-zayavku.svg';

const Opinion = () => {
  return (
    <div className="banners_item banners_opinion">
        <div className="banners_opinion_block">
            <h1 className="banners_opinion_title">Понравилось ли вам работать <a href="/about">с нами</a>?</h1>
            <span className="banners_opinion_text">Оставьте отзыв о нашей работе и получите<br/>приятный подарок!</span>
            <a href="/opinion" className="banners_consultation_form_submit">
                <img src={sendIcon} alt="Оставить отзыв о DenkArt" className="banners_consultation_form_submit_icon" />
                Оставить отзыв
            </a>
        </div>
    </div>
  )
}

export default Opinion