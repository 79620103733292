import React from 'react'

const Info = () => {
  return (
    <div className="banners_item banners_info">
        <div className="banners_info_block">
            <h1 className="banners_info_title">Почему Вы можете нам довериться?</h1>
            <span className="banners_info_text">Выбирая нас, Вы оберегаете себя от всевозможных рисков</span>
            <ul className="banners_info_list">
                <li className="banners_info_list_item">Гарантируем получение приглашения от выбранного университета <a href="/information#university">Австрии</a> или <a href="/information-germany#university">Германии</a></li>
                <li className="banners_info_list_item">Успешная подача документов на визу или ВНЖ в срок</li>
                <li className="banners_info_list_item">Успешное бронирование студенческого жилья на весь период обучения</li>
                <li className="banners_info_list_item">Помощь в адаптации по прибытии, оформлении и получении документов первой важности</li>
            </ul>
        </div>
    </div>
  )
}

export default Info