import './style.scss';
import freeConsSendIcon from '../../img/index/otpravit-zayavku.svg';

interface BtnSendType {
    label: string;
    handler: any
}

const BtnSend = ({label, handler}:BtnSendType) => {
  return (
    <div className="contact-form_submit" onClick={handler}>
        <img src={freeConsSendIcon} alt="Образовательный центр бесплатное первое занятие" className="contact-form_submit_icon" />
        <span>{label}</span>
    </div>
  )
}

export default BtnSend