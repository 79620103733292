import React, { useState } from "react";

interface IService {
    name: string;
    link: string;
  }
  
  interface Props {
    services: IService[];
    title: string;
  }

const DropdownButton = ({ services, title }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <>
      <button className="dropdown-btn" onClick={toggleDropdown}>
        {title} <i className="fa fa-caret-down"></i>
      </button>
      <div
        className="dropdown-container"
        style={{ display: isDropdownOpen ? "block" : "none" }}
      >
        {services.map((item)=>{
          return(<a href={item.link}>{item.name}</a>)
        })}
      </div>
    </>
  );
};

export default DropdownButton;