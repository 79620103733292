import './style.scss';

interface PageTitleType {
    firstTitle: string;
    secondTitle: string;
    textAlign: 'left' | 'right' | 'center';
}

const PageTitle = ({firstTitle, secondTitle, textAlign}: PageTitleType) => {
  return (
    <div className="page">
      <div className="page_container">
          <div className="page_wrapper">
              <h1 className="page_title" style={{textAlign: textAlign}}>
                  {firstTitle} <span className="page_title_orange">{secondTitle}</span>
              </h1>
          </div>
      </div>
    </div>
  )
}

export default PageTitle;