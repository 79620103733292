import './style.scss';

interface ItemSimpleType {
    value: string;
}

const ItemSimple = ({value}: ItemSimpleType) => {
  return (
    <div className="offers-item-simple">
        <div className="offers-item-simple_icon"></div>
        <h1 className="offers-item-simple_title">{value}</h1>
    </div>
  )
}

export default ItemSimple;