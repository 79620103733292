import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Index from './Pages/Index';
import About from './Pages/About';
import Opinion from './Pages/Opinion';

import './index.css';
import './museo/museo.css';
import './museo/normalize.css';
import News from './Pages/News';
import OffersAustria from './Pages/OffersAustria';
import OffersGermany from './Pages/OffersGermany';
import InformationAustria from './Pages/InformationAustria';
import InformationGermany from './Pages/InformationGermany';
import ModalOdds from './components/ModalOdds';
import School from './Pages/School';
import ModalCallback from './components/ModalCallback';
import ModalRecall from './components/ModalRecall';
import ModalFortune from './components/ModalFortune';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Header />
        <ModalOdds />
        {/* <ModalCallback /> */}
        <ModalFortune />
        <ModalRecall />
        <Routes>
            <Route path='/' element={<Index />} />
            <Route path='/about' element={<About />} />
            <Route path='/opinion' element={<Opinion />} />
            <Route path='/news' element={<News />} />
            <Route path='/offers' element={<OffersAustria />} />
            <Route path='/offers-germany' element={<OffersGermany />} />
            <Route path='/information' element={<InformationAustria />} />
            <Route path='/information-germany' element={<InformationGermany />} />
            <Route path='/school' element={<School />} />
            <Route path='*' element={<Index />} />
        </Routes>
        <Footer />
      </Router>
    </Provider>
  </React.StrictMode>
);
