import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  selectedComplex: string | null;
  country: string | null;
  isOpenModal: boolean;
}

const initialState: UserState = {
  selectedComplex: null,
  country: null,
  isOpenModal: false
};

const newOrderSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    openModal(state, action) {
      state.selectedComplex = action.payload.title;
      state.country = action.payload.country;
      state.isOpenModal = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
    }
  },
});

export const {
  openModal,
  closeModal
} = newOrderSlice.actions;

export default newOrderSlice.reducer;