import LocalizedStrings from 'react-localization';

let Localized = new LocalizedStrings({
    ru:{
      HeaderBtnAbout: "О нас",
      HeaderBtnEducation: "Образование за рубежом",
      HeaderBtnSchool: "Языковая школа",
      HeaderBtnOffers: "Услуги",
      HeaderBtnNews: "Новости",
      HeaderBtnAustria: "Австрия",
      HeaderBtnGermany: "Германия",

      FooterText: "Есть вопросы или пожелания? Свяжитесь с нами, мы будем рады!",
      FooterOfficeAustria: "Центральный офис в Вене",
      FooterOfficeGermany: "Офис в Германии",
      FooterOfficeAustriaAddress: "Вена 1020, Wehlistraße 330",
      FooterOfficeGermanyAddress: "Оснабрюк 49076, Sedanstraße 16",

      FooterBtnAgb: "Условия и положения",
      FooterBtnPolicy: "Политика конфиденциальности",
      FooterCopyright: "DenkArt 2023. Все права защищены",

    },
    pl: { 
      HeaderBtnAbout: "О nas",
      HeaderBtnEducation: "Edukacja za granicą",
      HeaderBtnSchool: "Szkoła językowa",
      HeaderBtnOffers: "Usługi",
      HeaderBtnNews: "Nowości",
      HeaderBtnAustria: "Austria",
      HeaderBtnGermany: "Niemcy",

      FooterText: "Masz pytania lub sugestie? Skontaktuj się z nami, będziemy szczęśliwi!",
      FooterOfficeAustria: "Centralne Biuro w Wiedniu",
      FooterOfficeGermany: "Biuro w Niemczech",
      FooterOfficeAustriaAddress: "Wiedeń 1020, Wehlistraße 330",
      FooterOfficeGermanyAddress: "Osnabrück 49076, Sedanstraße 16",

      FooterBtnAgb: "Regulamin",
      FooterBtnPolicy: "Polityka prywatności",
      FooterCopyright: "DenkArt 2023. Wszelkie prawa zastrzeżone",
    },

});

var lang = navigator.language;
if(lang === 'ru') {
  Localized.setLanguage('ru');
}
Localized.setLanguage('ru');
export default Localized;