import React from 'react'
import Opinions from '../../components/Opinions';
import Seo from '../../components/Seo';
import FreeConsultation from './Banners/FreeConsultation';
import Info from './Banners/Info';
import Lang from './Banners/Lang';
import Opinion from './Banners/Opinion';
import Slider from './Banners/Slider';
import Social from './Banners/Social';

import './style.scss';

const Index = () => {
  return (
    <>
        <Seo 
            titleTxt="Высшее образование в Австрии и Германии. Гарантия поступления. Изучение немецкого языка. DenkArt."
            description="Гарантированное поступление в ВУЗы Австрии и Германии по договору. Оформление документов, жилья и студенческой визы."
        />
        <div className="banners">
            <div className="banners_container">
                <div className="banners_wrapper">
                    <Slider />
                    <Lang/>
                    <FreeConsultation 
                        isGermany=""
                    />
                    <Info />
                    <Opinion />
                    <Opinions />
                    <Social />
                </div>
            </div>
        </div>

    </>
  )
}

export default Index;