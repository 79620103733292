import React from 'react';

import './style.scss';
import { useState } from 'react';
import { TextField } from '@mui/material';
import freeConsSendIcon from '../../../../img/index/otpravit-zayavku.svg';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

type FormConsultation = {
    first_name: string,
    email_address: string,
    phone_number: string,
    application_type: string,
};

const FreeConsultation = ({isGermany}: any) => {
    const [ isSending, setIsSending ] = useState(false);
    const [ isOpenResultDialog, setIsOpenResultDialog ] = useState(false);
    const [ modalInfo, setModalInfo ] = useState("");

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormConsultation>();

    const handleCloseResultDialog = () => {
        setIsOpenResultDialog(false);
    }

    const onSubmit: SubmitHandler<FormConsultation> = (data) =>  {

        setIsSending(true);

        data.application_type = "consultation";

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://api2.denkart.academy/api/application',
            data : dataSend
        };

        dataSend.append('data', JSON.stringify(data));

        axios(config)
        .then(function (response) {
            setIsSending(false);
            setIsOpenResultDialog(true);
            setModalInfo(response.data.message_user);
            reset();
        })
        .catch(function (error) {
            setIsSending(false);
            setIsOpenResultDialog(true);
            console.log(error);
            
            setModalInfo(error.response.data.message_user);
        });
    }

  return (
      <>
    <div className="banners_item banners_consultation">
        <div className={`banners_consultation_block${isGermany==="1" ? '-germany' : ''}`}>
            <h1 className="banners_consultation_title">Бесплатная консультация по поступлению в <a href="/offers">Австрию</a> и <a href="/offers-germany">Германию</a></h1>
            <span className="banners_consultation_text">Заполните форму и мы свяжемся с Вами<br />в течение одного рабочего дня</span>
            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                <TextField 
                    size='small'
                    placeholder="Ваше имя"
                    fullWidth
                    {...register("first_name", { required: true })}
                    error={!!errors.first_name}
                    InputProps={{
                        style: {
                            background: "#fff",
                            borderRadius: '10px', // Set the border radius value here
                            marginBottom: "8px"
                        },
                    }}
                />
                <TextField 
                    size='small'
                    placeholder="Email адрес"
                    fullWidth
                    {...register("email_address", { required: true })}
                    error={!!errors.email_address}
                    InputProps={{
                        style: {
                            background: "#fff",
                            borderRadius: '10px', // Set the border radius value here
                            marginBottom: "8px"
                        },
                    }}
                />
                <TextField 
                    size='small'
                    placeholder="Номер телефона"
                    fullWidth
                    {...register("phone_number", { required: true })}
                    error={!!errors.phone_number}
                    InputProps={{
                        style: {
                            background: "#fff",
                            borderRadius: '10px', // Set the border radius value here
                            marginBottom: "8px"
                        }
                    }}
                />
                <button className="contact-form_submit" onClick={() => onSubmit}>
                    <img src={freeConsSendIcon} alt="Образовательный центр бесплатное первое занятие" className="contact-form_submit_icon" />
                    <span>Отправить заявку</span>
                </button>
                
            </form>
        </div>
    </div>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSending}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Dialog
        open={isOpenResultDialog}
        onClose={handleCloseResultDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {modalInfo}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseResultDialog} autoFocus>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default FreeConsultation