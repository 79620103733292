import icon from '../../../img/offers/info.svg';
import './style.scss';

interface ButtonInformationType {
    link: string;
}

const ButtonInformation = ({link}:ButtonInformationType) => {
  return (
    <a href={link} className="offers-button-download" rel="noreferrer" target="_blank">
        <img src={icon} alt="Подробная информация" className="button_icon" />
        Подробная информация
    </a>
  )
}

export default ButtonInformation;