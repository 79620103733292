import React, { ReactNode } from 'react';
import './style.scss';

import giftIcon from '../../../img/offers/gift.svg';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/newOrderSlice';

type Props = {
  children: ReactNode;
  title: string;
  color: string;
  country: string;
  oldPrice: string;
  currentPrice: string;
};

const ComplexItem = ({ children, title, country, color, oldPrice, currentPrice }: Props) => {

  const dispatch = useAppDispatch();


  return (
    <>
    <div className="complex_item">
      <div className="complex_item_header" style={{background: color}}>
        <h1 className="complex_item_header_title">{title}</h1>
      </div>
      <div className="complex_item_body">
        {children}
      </div>
      <div className="complex_item_footer">
        <div className="complex_item_footer_tr"></div>
        <div className="complex_item_footer_price">
          <span className="complex_item_footer_price_now">{currentPrice}€</span>
          <span className="complex_item_footer_price_old">{oldPrice}€</span>
        </div>
        {/* <span className="complex_item_footer_text">Включает 20% НДС</span> */}
        <div className="complex_item_footer_gift">
          <img src={giftIcon} alt="Gift Icon" className="complex_item_footer_gift_icon" />
          <span className="complex_item_footer_gift_text">Оформите и получите подарок</span>
        </div>

        <input className="complex-order-btn" type="button"
          value="Перейти к оформлению" style={{background: color}} onClick={()=> dispatch(openModal({title, country}))}/>

      </div>
    </div>
    </>
   
  )
}

export default ComplexItem;