import Localized from '../../Localized';

import './style.scss';

import iconInsta from '../../img/header/instagram-ikonka.svg';
import iconVk from '../../img/header/vk-ikonka.svg';
import iconTelegram from '../../img/header/telegram-ikonka.svg';

import youtubeIcon from '../../img/social/footer/youtube.svg';

import logo from '../../img/index/flag-logotip-denkart.svg';
import flagAustria from '../../img/index/flag-avstrii-podval.svg';
import flagGermany from '../../img/index/flag-germanii-podval.svg';
import iconMail from '../../img/index/besplatnaya-konsultaciya-pochta.svg';
import iconPhone from '../../img/index/besplatnaya-konsultaciya-telefon.svg';

import iconWKO from '../../img/index/ikonka-wko.png';
import Recall from '../recall';



const Footer = () => {
  return (
    <> 
        <footer className="footer">
            <div className="footer_container">
                <div className="footer_wrapper">
                    <div className="footer_left">
                        <a href="/">
                            <img src={logo} alt="Логотип DenkArt" className="footer_left_logo" />
                        </a>
                        <span className="footer_left_text">{Localized.FooterText}</span>
                        <div className="footer_social">
                            <a href="https://instagram.com/denkart_academy" target="_blank" rel="noreferrer" className="footer_social_item">
                                <img src={iconInsta} alt="Инстаграм DenkArt" className="footer_social_item_img"/>
                            </a>
                            <a href="https://vk.com/public210644128" target="_blank" rel="noreferrer" className="footer_social_item">
                                <img src={iconVk} alt="VK DenkArt" className="footer_social_item_img"/>
                            </a>
                            <a href="https://t.me/denkart_da" target="_blank" rel="noreferrer" className="footer_social_item">
                                <img src={iconTelegram} alt="Telegram DenkArt" className="footer_social_item_img"/>
                            </a>
                            <a href="https://youtube.com/channel/UCXVUavN9-TgMPsK8ya6twew" target="_blank" rel="noreferrer"  className="footer_social_item">
                                <img src={youtubeIcon} alt="Youtube DenkArt" className="footer_social_item_img" />
                            </a>
                        </div>
                    </div>
                    <div className="footer_offices">
                        <div className="footer_offices_item">
                            <div className="footer_offices_item_header">
                                <img src={flagAustria} alt="Австрия поступление" className="footer_offices_item_header_flag"/>
                                <div className="footer_offices_item_header_block">
                                    <span className="footer_offices_item_header_name">{Localized.HeaderBtnAustria}</span>
                                    <span className="footer_offices_item_header_city">{Localized.FooterOfficeAustria}</span>
                                </div>
                            </div>    
                            <span className="footer_offices_item_adress">{Localized.FooterOfficeAustriaAddress}</span>
                            <div className="footer_offices_item_mail">
                                <img src={iconMail} alt="Электронная почта Австрия DenkArt" className="footer_offices_item_mail_icon"/>
                                <a href="mailto:info@denkart.academy" rel="nofollow" className="footer_offices_item_mail_link">info@denkart.academy</a>
                            </div>
                            <div className="footer_offices_item_phone">
                                <img src={iconPhone} alt="Телефон Австрия DenkArt" className="footer_offices_item_phone_icon"/>
                                <a href="tel:+4367761208494" rel="nofollow" className="footer_offices_item_phone_link">+43 677 61208494</a>
                            </div>
                        </div>
                        <div className="footer_offices_item">
                            <div className="footer_offices_item_header">
                                <img src={flagGermany} alt="Германия поступление" className="footer_offices_item_header_flag"/>
                                <div className="footer_offices_item_header_block">
                                    <span className="footer_offices_item_header_name">{Localized.HeaderBtnGermany}</span>
                                    <span className="footer_offices_item_header_city">{Localized.FooterOfficeGermany}</span>
                                </div>
                            </div>    
                            <span className="footer_offices_item_adress">{Localized.FooterOfficeGermanyAddress}</span>
                            <div className="footer_offices_item_mail">
                                <img src={iconMail} alt="Электронная почта Германии DenkArt" className="footer_offices_item_mail_icon"/>
                                <a href="mailto:info@denkart.academy" rel="nofollow" className="footer_offices_item_mail_link">info@denkart.academy</a>
                            </div>
                            <div className="footer_offices_item_phone">
                                <img src={iconPhone} alt="Телефон Германия DenkArt" className="footer_offices_item_phone_icon"/>
                                <a href="tel:+491786812028" rel="nofollow" className="footer_offices_item_phone_link">+49 178 6812028</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_wko">
                    <img src={iconWKO} alt="WKO - знак гарантии качества" className="footer_left_wko"/>
                    <div className="footer_wko_links">
                        <a href="/docs/ru_agb.pdf" target="_blank" rel="noreferrer" className="footer_wko_link">{Localized.FooterBtnAgb}</a>
                        <a href="/docs/ru_polit.pdf"  target="_blank" rel="noreferrer" className="footer_wko_link">{Localized.FooterBtnPolicy}</a>
                    </div>
                    <span className="footer_left_copyright">{Localized.FooterCopyright}</span>
                </div>
                <Recall />
            </div>
        </footer>
    </>
  )
}

export default Footer