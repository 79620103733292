import React from 'react';

interface TeamItem  {
    photo: string;
    name: string; 
    description: string;
}

const TeamItem = ({photo, name, description}:TeamItem) => {
  return (
    <div className="team_item">
        <div className="team_item_photo">
            <img src={photo} alt={name} className="team_item_photo_img" />
        </div>
        <div className="team_item_info">
            <span className="team_item_info_name">{name}</span>
            <span className="team_item_info_position">{description}</span>
        </div>
    </div>
  )
}

export default TeamItem