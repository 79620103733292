import { Box, FormControl, LinearProgress, Modal } from '@mui/material';
import  { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { closeModal } from '../../redux/slices/checkOddsSlice';
import freeConsSendIcon from '../../img/index/otpravit-zayavku.svg';

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios from "axios";

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ru from 'react-phone-input-2/lang/ru.json';

import '../ModalOdds/quiz.scss';

type FormConsultation = {
    first_name: string,
    email_address: string,
    phone_number: string,
    application_type: string,
    country: string,
};

const ModalRecall = () => {

    const { isOpenModalRecall } = useAppSelector((state: RootState) => state.checkOdds);
    const [step, setStep] = useState({
        current: 0,
        max: 1,
        isSending: false,
    });

    const { control, register, handleSubmit, formState: { errors }, reset } = useForm<FormConsultation>();
    const dispatch = useAppDispatch();
    const [country, setCountry] = useState("");

    const handleChangeCountry = (value: any, country: any) => {

        setCountry(country.name);
        console.log(country.name);
        
    }

    const onSubmit: SubmitHandler<FormConsultation> = (data) =>  {
        setStep({
            ...step,
            isSending: true,
        });

        data.application_type = "consultation_by_phone";
        data.country = country;

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://api2.denkart.academy/api/application',
            data : dataSend
        };

        dataSend.append('data', JSON.stringify(data));

        axios(config)
        .then(function (response) {
            setStep({
                ...step,
                current: 1,
                isSending: false,
            });
            reset();
        })
        .catch(function (error) {
            alert(error);
        });
    }

    return (
        <Modal
            open={isOpenModalRecall}
            onClose={() => dispatch(closeModal())}
        >
            <Box className="check-odds">
                <div className="check-odds_container">
                    <div className="check-odds_content">
                        <div className="check-odds_header">
                            <div className="check-odds_close" onClick={()=>dispatch(closeModal())}></div>
                            <h2>У вас есть вопросы по образованию в Австрии или Германии?</h2>
                            <h5>Мы будем рады на них ответить! Оставьте ваш номер, и мы вам перезвоним!</h5>
                        </div>
                        <div className="check-odds_body">
                            <form onSubmit={handleSubmit(onSubmit)} className="contact-form" style={step.current === 0 ? {display: "flex"}: {display: "none"}}>
                                <FormControl className="application-modal_question" fullWidth size="small">
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        render={({ field }) => (
                                        <ReactPhoneInput
                                            {...register("phone_number", { required: true })}
                                            {...field}
                                            value={field.value} // Set the default country value
                                            country='by'
                                            localization={ru}
                                            inputProps={{
                                                name: 'contactPhone',
                                                placeholder: 'Начните писать тут...',
                                                required: true,
                                                autoformat: true,
                                            }}
                                            onChange={(value, country) => {field.onChange(value); handleChangeCountry(value, country)}} // Update the field value on change
                                        />
                                        )}
                                    />
                                    {errors.phone_number && (
                                        <span className="application-modal_question_error">
                                            Обязательное поле
                                        </span>
                                    )}
                                </FormControl>

                                <span className="check-odds_contact_item_text">*где есть Viber, WhatsApp, Telegram</span>
                                <button className="contact-form_submit" onClick={() => onSubmit}>
                                    <img src={freeConsSendIcon} alt="Образовательный центр консультация" className="contact-form_submit_icon" />
                                    <span>Жду звонка!</span>
                                </button>
                                
                            </form>
                            { step.isSending && <LinearProgress 
                            color="secondary"
                            style={{marginTop: "10px", width: "200px"}}
                            /> }
                        </div>
                        <div className="check-odds_final" style={step.current === 1 ? {display: "flex"}: {display: "none"}}>
                            <span className="check-odds_final_text">Спасибо!<br/>Мы оперативно свяжемся с Вами в течение рабочего дня!</span>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalRecall;